import React from 'react'
import {Button, Card, Divider, Image, ProgressCircle, Tag} from 'antd-mobile'
import Location from '../../svg/home/Location.svg'
import Chart from '../../svg/home/Chart.svg'
import Price from '../../svg/home/Price.svg'
import Money from '../../svg/home/Money.svg'
import NoAsset from '../../svg/home/NoAsset.svg'
import {useNavigate} from 'react-router-dom'
import {MoreOutline} from 'antd-mobile-icons'
import {Badge, Col, Row, Skeleton} from 'antd'
import {
    formatBackgroundColorContractStatus,
    formatColorContractStatus,
    formatContractStatus,
    formatDate,
    formatNumber
} from '../../util/helper'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import consigneXLogo from '../../svg/additional/logo.webp'

interface ListPropertyProps {
    propertyData: any;
    loading: boolean;
    contractType: any;
}

const ListProperty: React.FC<ListPropertyProps> = (props) => {
    const navigate = useNavigate()
    const data = props.propertyData

    const tagColor = (status: string) => {
        if (status === 'ชำระปกติ') {
            return '#14B724'
        } else {
            return '#FF0000'
        }
    }

    const tagBackgroundColor = (status: string) => {
        if (status === 'ชำระปกติ') {
            return '#D9F7BE'
        } else {
            return '#FFC0CB'
        }
    }

    return (
        <>
            <Card className="cardStyleShadow">
                <Skeleton active loading={props.loading} paragraph={{rows: 3}}>
                    <>
                        <Row>
                            <Col span={12}>
                                <span className="text-[16px] font-bold">เลขที่สัญญา</span>
                            </Col>
                            <Col span={12} className="text-right">
                                <Tag
                                    round
                                    color="#FFDFE1"
                                    style={{
                                        '--text-color': 'var(--adm-color-text)',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        padding: '4px 8px',
                                    }}
                                >
                                    {data?.contractId || 'ไม่มีข้อมูล'}
                                </Tag>
                            </Col>
                        </Row>
                        <Row className="mt-[1em]">
                            <Col span={12} className="text-center">
                                <ProgressCircle
                                    percent={parseInt(formatNumber((data?.transferAmount * 100) / data?.amountInterest || 0))}
                                    style={{
                                        '--size': '90px',
                                        '--track-width': '8px',
                                        '--fill-color': '#52C41A',
                                    }}
                                >
                                    <div style={{
                                        fontSize: '15px',
                                        fontWeight: 'bold'
                                    }}>{parseInt(formatNumber((data?.transferAmount * 100) / data?.amountInterest || 0))} %
                                    </div>
                                    <div style={{fontSize: '10px', color: 'var(--adm-color-weak)'}}>Complete</div>
                                </ProgressCircle>
                            </Col>
                            <Col span={10} offset={2}>
                                <Badge
                                    color="#52C41A"
                                    text={
                                        <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                            ดอกเบี้ยที่ชำระแล้ว
                                        </span>
                                    }
                                />
                                <div style={{fontWeight: 'bold', marginLeft: '13px'}}>
                                    THB {data?.transferAmount ? Math.trunc((data?.transferAmount * 100) / 100).toLocaleString() : 0}
                                </div>
                                <Badge
                                    color="red"
                                    text={
                                        <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                            ดอกเบี้ยคงค้าง
                                        </span>
                                    }
                                />
                                <div style={{fontWeight: 'bold', marginLeft: '13px'}}>
                                    THB {data?.accruedInterest ? Math.trunc((data?.accruedInterest * 100) / 100).toLocaleString() : 0}
                                </div>
                                <Badge
                                    color="#DCDCDC"
                                    text={
                                        <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                            ดอกเบี้ยคงเหลือ
                                        </span>
                                    }
                                />
                                <div style={{fontWeight: 'bold', marginLeft: '13px'}}>
                                    THB {data?.remainingInterest ? Math.trunc((data?.remainingInterest * 100) / 100).toLocaleString() : 0}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '15px'}}>
                            <Col span={12}>
                                <span>ยอดชำระทั้งหมด</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right'}}>
                                <span style={{fontWeight: 'bold'}}>
                                        THB{' '}
                                    <span>
                                        {data?.amountInterest
                                            ? Math.trunc((data?.amountInterest * 100) / 100).toLocaleString()
                                            : 0}
                                    </span>
                                </span>
                            </Col>
                        </Row>
                        <Divider className="my-[0.5em] mx-0"/>
                        <Row style={{backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px'}}>
                            <Col span={8}>
                                <div style={{fontWeight: 'bold'}}>สถานะ</div>
                            </Col>
                            <Col span={16} style={{textAlign: 'right'}}>
                                <div key="status" style={{marginBottom: '5px'}}>
                                    <Tag
                                        style={{
                                            fontSize: 12,
                                            padding: '4px',
                                            color: tagColor(data?.contractStatus),
                                            borderColor: tagColor(data?.contractStatus),
                                            backgroundColor: tagBackgroundColor(data?.contractStatus),
                                        }}
                                    >
                                        {data?.contractStatus}
                                    </Tag>
                                </div>
                                <div style={{color: '#14B724'}}>
                                    <ExclamationCircleOutlined/> ชำระทุกวันที่ {data.dayToPay || 'ไม่มีกำหนด'} ของเดือน
                                </div>
                            </Col>
                        </Row>
                    </>
                </Skeleton>
            </Card>
            <div style={{margin: '2em 0'}}>
                <Skeleton active loading={props.loading} paragraph={{rows: 3}}>
                    {Object.keys(data).length > 0 ? (
                        <div>
                            <div key="periodPayment" style={{marginBottom: '10px'}}>
                                <Row style={{marginBottom: '10px'}}>
                                    <Col span={12}>
                                        <div style={{fontWeight: 600}}>การชำระที่กำลังมาถึง</div>
                                    </Col>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <Tag
                                            color="#DADADA"
                                            round
                                            style={{
                                                '--text-color': 'var(--adm-color-text)',
                                                fontSize: '12px',
                                                fontWeight: 700,
                                                padding: '4px 8px',
                                            }}
                                        >
                                            {data?.nextRound}
                                        </Tag>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <div style={{fontSize: '16px', fontWeight: 600}}>
                                            ฿{data?.amountNextRound ? data?.amountNextRound.toLocaleString() : '0'}
                                        </div>
                                    </Col>
                                    <Col span={12} style={{textAlign: 'right'}}>
                                        <div style={{color: 'red'}}>
                                            ครบกำหนด {formatDate(data.dueDate || 'ไม่มีกำหนด')}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Button
                                color="primary"
                                style={{backgroundColor: '#0A52BD', marginBottom: '1em', width: '100%'}}
                                onClick={() => navigate('/sendProof', {
                                    state: {
                                        tab: 'แจ้งหลักฐาน'
                                    }
                                })}
                            >
                                ส่งหลักฐานการชำระเงิน
                            </Button>
                            <div style={{fontSize: 16, fontWeight: 'bold', padding: '5px 0'}}>รายละเอียดทรัพย์</div>
                            <div
                                className="spaceBetween"
                                style={{marginBottom: '1em'}}
                                onClick={() => navigate(`/property/${encodeURIComponent(data?.contractId)}`, {state: {tab: 'assetDetail'}})}
                            >
                                <Image
                                    src={data?.asset?.image !== null ? data?.asset?.image : consigneXLogo}
                                    height={100}
                                    width={100}
                                    fit="cover"
                                    className="rounded-[10px]"
                                />
                                <div style={{fontWeight: 600, fontSize: 12, maxWidth: 'calc(100% - 120px)'}}>
                                    <div style={{fontWeight: 700, fontSize: 14}}>
                                        {data?.asset?.assetName || '-'}
                                    </div>
                                    <div className="flex items-center">
                                        <img src={Location} alt="location" height={14} width={14}/>
                                        <span style={{marginLeft: '1em'}}>
                                            {data?.asset?.assetAddress ? data?.asset?.assetAddress : '-'}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={Chart} alt="chart" height={14} width={14}/>
                                        <span className="ml-[1em]">
                                        พื้นที่ทรัพย์ :
                                            {` ${data?.asset?.areaRai} ไร่`}
                                            {` ${data?.asset?.areaNgan} งาน`}
                                            {` ${data?.asset?.areaTaRangWa} ตร.ว`}
                                            {` ${data?.asset?.areaSquareMeter} ตร.ม`}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={Price} alt="price" height={14} width={14}/>
                                        <span style={{marginLeft: '1em'}}>
                                        ราคาประเมิน : {data?.asset?.estimatedAmount.toLocaleString() || '-'} บาท
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={Money} alt="money" height={14} width={14}/>
                                        <span style={{marginLeft: '1em'}}>
                                        อนุมัติราคา : {data?.asset?.approvedAmount.toLocaleString() || '-'} บาท
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Divider className="my-[1em] mx-0"/>
                            <div>
                                <div className="spaceBetween">
                                    <span className="text-[16px] font-bold">รายละเอียดสัญญา</span>
                                    <MoreOutline
                                        fontSize={24}
                                        onClick={() => navigate(`/property/${encodeURIComponent(data?.contractId)}`, {state: {tab: 'contractDetail'}})}
                                    />
                                </div>
                                <Divider style={{margin: '1em 0'}}/>
                                <Row>
                                    <Col span={12}>
                                        เลขที่สัญญา
                                    </Col>
                                    <Col key="contractId" span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                        {data?.contractId || 'ไม่มีเลขสัญญา'}
                                    </Col>
                                </Row>
                                <Divider style={{margin: '1em 0'}}/>
                                <Row>
                                    <Col span={12}>
                                        วันที่ทำสัญญา
                                    </Col>
                                    <Col key="contractDate" span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                        {formatDate(data?.contractDate || 'ไม่มีวันที่ทำสัญญา')}
                                    </Col>
                                </Row>
                                <Divider style={{margin: '1em 0'}}/>
                                <Row>
                                    <Col span={12}>
                                        เอกสารสิทธิ์
                                    </Col>
                                    <Col key="contractDate" span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                        {data?.document || 'ไม่มีเอกสารสิทธิ์'}
                                    </Col>
                                </Row>
                                <Divider style={{margin: '1em 0'}}/>
                                <Row>
                                    <Col span={12}>
                                        สถานะ
                                    </Col>
                                    <Col key="contractDate" span={12} style={{textAlign: 'right', fontWeight: 'bold'}}>
                                        <Tag
                                            style={{
                                                fontSize: 12,
                                                padding: '4px',
                                                color: formatColorContractStatus(data?.status),
                                                borderColor: formatColorContractStatus(data?.status),
                                                backgroundColor: formatBackgroundColorContractStatus(data?.status),
                                            }}
                                        >
                                            {formatContractStatus(data?.status || 'ไม่มีสถานะ')}
                                        </Tag>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '1em 0'}}/>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center px-[5em] py-[1em]">
                            <img src={NoAsset} alt="noAsset"/>
                            <div className="text-[20px] font-semibold pt-[1em]">
                                คุณยังไม่มีทรัพย์ที่ทำธุรกรรม
                            </div>
                        </div>
                    )}
                </Skeleton>
            </div>
        </>
    )
}

export default ListProperty
