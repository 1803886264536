import React from 'react'
import {NavBar} from 'antd-mobile'
import {useNavigate} from 'react-router-dom'
import {Typography} from 'antd'

interface NavbarProps {
    title: string;
    back?: boolean
}

const NavbarComponent: React.FC<NavbarProps> = ({title, back = false}) => {
    const navigate = useNavigate()

    return (
        <div>
            <NavBar
                className="text-white h-[60px]"
                backArrow={back}
                onBack={back ? () => navigate(-1) : undefined}
            >
                <Typography.Title level={4} className="text-white font-bold my-[0.5em] mx-0">
                    {title}
                </Typography.Title>
            </NavBar>
        </div>
    )
}

export default NavbarComponent
