import React, {useEffect, useState} from 'react'
import {Avatar} from 'antd-mobile'
import {UnorderedListOutlined} from '@ant-design/icons'
import PopupSelectContract from '../components/PopupSelectContract'
import logo from '../svg/home/ConsigneXLogo.svg'
import ListProperty from './property/ListProperty'
import AxiosConsigneX from '../config/AxiosConfig'
import {Spin} from 'antd'
import consigneXLogo from '../svg/additional/logo.webp'
import liff from '@line/liff'

const Home = () => {
    const [popupSelectVisible, setPopupSelectVisible] = useState(false)
    const [propertyData, setPropertyData] = useState<any>({})
    const [contractData, setContractData] = useState<any>([])
    const [contractType, setContractType] = useState<string>('')
    const [contractLoading, setContractLoading] = useState<boolean>(false)
    const [propertyLoading, setPropertyLoading] = useState<boolean>(false)
    const [pictureUrl, setPictureUrl] = useState<string>(consigneXLogo)
    const phoneNumber = localStorage.getItem('phoneNumber')
    const [displayName, setDisplayName] = useState(phoneNumber)

    useEffect(() => {
        fetchContract()
        getProfile()
    }, [])

    useEffect(() => {
        if (contractData && contractData.length > 0) {
            fetchProperty(contractData[0].id)
            localStorage.setItem('transactionType', contractData[0].transactionType)
            localStorage.setItem('contractId', contractData[0].id)
        }
    }, [contractData])

    const fetchContract = async () => {
        setContractLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract')
            setContractData(data)
            setContractType(data[0].transactionType)
        } catch (e) {
            // handle in interceptor
        }
        setContractLoading(false)
    }

    const fetchProperty = async (contractId: string) => {
        setPropertyLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/detail', {
                params: {
                    contractId: contractId
                }
            })
            setPropertyData(data)
        } catch (e) {
            // handle in interceptor
        }
        setPropertyLoading(false)
    }

    const getProfile = async () => {
        if (process.env.REACT_APP_ENV === 'production' && liff.isLoggedIn()) {
            try {
                const profile = await liff.getProfile()
                setDisplayName(profile.displayName)
                setPictureUrl(profile.pictureUrl || '')
            } catch (e) {
                // handle interceptor
            }
        } else {
            // handle in interceptor
        }
    }

    return (
        <>
            <PopupSelectContract
                visible={popupSelectVisible}
                setVisible={setPopupSelectVisible}
                fetchProperty={fetchProperty}
                contractData={contractData}
                loading={contractLoading}
                setContractType={setContractType}
            />
            <Spin spinning={contractLoading} size="large" style={{minHeight: '100vh'}}>
                <div className="backgroundHome" style={{padding: '1em 1.5em 0'}}>
                    <div className="spaceBetween">
                        <img src={logo} alt="logo" height={50} width={80}/>
                        <div className="contentCenter">
                            <span style={{fontSize: '14px', color: 'white'}}>{displayName}</span>
                            <Avatar
                                src={pictureUrl}
                                style={{borderRadius: '50%', marginLeft: '1em'}}
                            />
                        </div>
                    </div>
                    <div className="spaceBetween" style={{color: 'white'}}>
                        <p style={{fontSize: '16px', fontWeight: 'bold', margin: '10px 0'}}>ความคืบหน้าในการชำระหนี้</p>
                        <UnorderedListOutlined style={{fontSize: '20px'}} onClick={() => setPopupSelectVisible(true)}/>
                    </div>
                    <ListProperty propertyData={propertyData} loading={propertyLoading} contractType={contractType}/>
                </div>
            </Spin>
        </>
    )
}

export default Home
