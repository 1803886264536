import React from 'react'
import {Outlet, useParams} from 'react-router-dom'
import NavbarComponent from '../../components/Navbar'

const Document = () => {
    const {folderName} = useParams()

    const handleTitle = () => {
        if (folderName === 'Personal') {
            return 'เอกสารส่วนบุคคล'
        } else if (folderName === 'Assurance') {
            return 'เอกสารค้ำประกัน'
        } else if (folderName === 'Financial') {
            return 'เอกสารการเงิน'
        } else {
            return 'เอกสารสิทธิ์และอื่น ๆ'
        }
    }

    return (
        <div className="backgroundPage">
            <NavbarComponent
                title={handleTitle()}
                back={folderName !== undefined}
            />
            <div className="backgroundContent">
                <Outlet/>
            </div>
        </div>
    )
}

export default Document
