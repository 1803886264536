import React, {useEffect, useState} from 'react'
import {Typography} from 'antd'
import {Image, ImageViewer, NavBar} from 'antd-mobile'
import {useNavigate, useParams} from 'react-router-dom'
import AxiosConsigneX from '../../config/AxiosConfig'

const ShowImages = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [images, setImages] = useState<string[]>([])

    useEffect(() => {
        getImages()
    }, [])

    const getImages = async () => {
        try {
            const {data} = await AxiosConsigneX.get('/asset/assetImages', {
                params: {
                    contractId: params.propertyId
                }
            })
            setImages(data)
        } catch (e) {
            // handle in interceptor
        }
    }

    const imagePreview = (index: number) => {
        ImageViewer.Multi.show({
            images: images.map(item => item),
            defaultIndex: index,
        })
    }

    return (
        <div>
            <NavBar style={{'--height': '55px'}} onBack={() => navigate(-1)}>
                <Typography.Title level={4} style={{margin: 0, fontWeight: 'bold'}}>รูปภาพทั้งหมด</Typography.Title>
            </NavBar>
            <div className="flex flex-wrap">
                {images.map((item, index) => (
                    <div
                        key={index}
                        style={{width: index % 3 === 0 ? '100%' : '50%', boxSizing: 'border-box', padding: '0.25em'}}
                        onClick={() => imagePreview(index)}
                    >
                        <Image
                            key={`image ${index}`}
                            src={item}
                            alt="image"
                            className='object-cover w-[100%] h-[100%]'
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ShowImages
