import React from 'react'
import {Button, Modal} from 'antd'
import {CloseCircleFilled} from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'

interface ModalRejectProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    dataPayment: any;
}

const ModalVisibleReject: React.FC<ModalRejectProps> = ({visible, setVisible, dataPayment}) => {
    const navigate = useNavigate()

    return (
        <>
            <Modal
                title={
                    <span>
                        <CloseCircleFilled className="text-[#ff4d4f] mr-[8px]"/>
                        เหตุผลในการปฏิเสธ :
                    </span>
                }
                footer={null}
                open={visible}
                onCancel={() => setVisible(false)}
            >
                <div style={{marginBottom: '5em'}}>
                    {dataPayment.rejectReason}
                </div>
                <Button
                    type="primary"
                    block
                    className="bg-[#0A52BD] mb-[1em] w-[100%]"
                    onClick={() => {
                        setVisible(false)
                        navigate('/sendProof', {
                            state: {
                                tab: 'แจ้งหลักฐาน',
                                round: dataPayment.round,
                            },
                            replace: true
                        })
                    }}
                >
                    ส่งหลักฐาน
                </Button>
            </Modal>
        </>
    )
}

export default ModalVisibleReject
