import React from 'react'
import TabBarComponent from './Tabbar'
import {Outlet} from 'react-router-dom'

const Layout = () => {
    return (
        <div className="App">
            <div className="body">
                <Outlet/>
            </div>
            <div className="bottom">
                <TabBarComponent/>
            </div>
        </div>
    )
}

export default Layout
