import React from 'react'
import {Button, Modal} from 'antd-mobile'
import {CheckCircleFilled} from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'

interface ModalRegisterSuccessProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const ModalRegisterSuccess: React.FC<ModalRegisterSuccessProps> = ({visible, setVisible}) => {
    const navigate = useNavigate()

    return (
        <>
            <Modal
                header={<CheckCircleFilled className="text-[64px] text-[#4EC47F]"/>}
                title="ลงทะเบียนเรียบร้อยแล้ว"
                visible={visible}
                content={
                    <Button
                        fill="outline"
                        color="primary"
                        className="w-[100%]"
                        onClick={() => {
                            setVisible(false)
                            navigate('/additional')
                        }}
                    >
                        กลับสู่เมนูหลัก
                    </Button>
                }
            />
        </>
    )
}

export default ModalRegisterSuccess
