import React from 'react'
import {List, Popup} from 'antd-mobile'
import ContractIcon from '../svg/home/ContractIcon.svg'
import {Empty, Skeleton} from 'antd'

interface PopupSelectContractProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    fetchProperty: (contractId: string) => void;
    contractData: ContractData[];
    loading: boolean;
    setContractType: (contractType: string) => void;
}

interface ContractData {
    id: string;
    transactionType: string;
}

const PopupSelectContract: React.FC<PopupSelectContractProps> = (props) => {

    return (
        <Popup
            visible={props.visible}
            onClose={() => {
                props.setVisible(false)
            }}
            onMaskClick={() => {
                props.setVisible(false)
            }}
            bodyClassName="h-[70vh]"
        >
            <div className="mx-[1em]">
                <div className="text-[1.5em] font-bold my-[16px]">เลือกสัญญา</div>
                {!props.loading ? (
                    !props.contractData || props.contractData.length <= 0 ? (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="ไม่พบข้อมูล"
                            className="px-[4em] py-0"
                        />
                    ) : (
                        <List className="overflow-y-scroll h-[60vh]">
                            {props.contractData.map((item: ContractData, index: number) => (
                                <List.Item
                                    key={index}
                                    onClick={() => {
                                        props.fetchProperty(item.id)
                                        props.setContractType(item.transactionType)
                                        props.setVisible(false)
                                        localStorage.setItem('transactionType', item.transactionType)
                                        localStorage.setItem('contractId', item.id)
                                    }}
                                    prefix={<img src={ContractIcon} alt="icon"/>}
                                    extra={item.id}
                                    arrow={false}
                                >
                                    <span className="font-medium">สัญญา{item.transactionType}</span>
                                </List.Item>
                            ))}
                        </List>
                    )
                ) : (
                    <Skeleton active/>
                )}
            </div>
        </Popup>
    )
}

export default PopupSelectContract
