import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Image, NavBar} from 'antd-mobile'
import {DownloadOutlined} from '@ant-design/icons'
import AxiosConsigneX from '../../config/AxiosConfig'
import {Spin} from 'antd'
import {Document, Page, pdfjs} from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString()

const ShowFile = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [file, setFile] = useState<any>(null)
    const [numPages, setNumPages] = useState(0)

    useEffect(() => {
        getFile()
    }, [])

    const getFile = async () => {
        setLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/eachFileContractDebtor', {
                params: {
                    documentId: params.fileId,
                    contractId: params.contractId
                }
            })
            setFile(data)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    const downloadFile = async () => {
        try {
            const {data} = await AxiosConsigneX.get('/contract/downloadDocumentDebtor', {
                params: {
                    uid: params.fileId,
                    contractId: params.contractId
                },
                responseType: 'blob'
            })
            const blob = new Blob([data])
            const dataUrl = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = dataUrl
            a.download = file?.title || 'unknown'
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(dataUrl)
            document.body.removeChild(a)
        } catch (e) {
            // handle in interceptor
        }
    }

    const onDocumentLoadSuccess = ({numPages}: any) => {
        setNumPages(numPages)
    }

    return (
        <div>
            <Spin spinning={loading} size="large">
                <NavBar
                    style={{
                        padding: '2px 12px',
                        backgroundColor: 'white',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        '--height': 'auto'
                    }}
                    right={<DownloadOutlined className="text-[22px]" onClick={downloadFile}/>}
                    onBack={() => navigate(-1)}
                >
                    <div className="font-semibold text-[15px] whitespace-pre-line">
                        {file?.title}
                    </div>
                </NavBar>
                <div className="mt-[1em] flex justify-center">
                    {file && file.title.endsWith('.pdf') ? (
                        <Document
                            file={file?.src}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false}
                                    width={window.innerWidth}
                                />
                            ))}
                        </Document>
                    ) : (
                        <Image
                            src={file?.src}
                            width="100%"
                        />
                    )}
                </div>
            </Spin>
        </div>
    )
}

export default ShowFile
