import React, { useEffect, useState } from 'react'
import { Button, Divider, Tag, Spin, Typography, Row, Col } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import AxiosConsigneX from '../../../config/AxiosConfig'
import {
    formatBackgroundColorContractStatus,
    formatColorContractStatus,
    formatContractStatus,
    formatDate
} from '../../../util/helper'

const TabContractDetail = () => {
    const navigate = useNavigate()
    const { propertyId } = useParams()
    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchContract()
    }, [])

    const fetchContract = async () => {
        setLoading(true)
        try {
            const { data } = await AxiosConsigneX.get('/contract/contractDetail', {
                params: {
                    contractId: decodeURIComponent(propertyId!),
                },
            })
            setData(data)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    const detailRows = [
        { label: 'เลขที่สัญญา', value: data.contractId || 'ไม่มีเลขสัญญา' },
        { label: 'ชื่อผู้ทำธุรกรรม', value: data.debtorName || '-' },
        { label: 'ชื่อผู้รับ', value: data.investorName || '-' },
        { label: 'วันที่ทำสัญญา', value: formatDate(data.contractDate || '-') },
        { label: 'ครบกำหนดสัญญา', value: formatDate(data.dueDate || '-') },
        { label: 'เอกสารสิทธิ', value: data.document || '-' },
        { label: 'สถานะ', value:
            <Tag
                style={{
                    fontSize: 12,
                    padding: '4px',
                    color: formatColorContractStatus(data?.status),
                    borderColor: formatColorContractStatus(data?.status),
                    backgroundColor: formatBackgroundColorContractStatus(data?.status),
                }}
            >
                {formatContractStatus(data?.status)}
            </Tag> },
        { label: 'ประเภท', value: data.transactionType || '-' },
        { label: 'ระยะเวลาสัญญา', value: data.duration ? data.duration + ' เดือน' : '-' },
        { label: 'ดอกเบี้ยต่อเดือน', value: data.interestPerMonth ? data.interestPerMonth.toFixed(2) + '%' : '-' },
        { label: 'ค่าใช้จ่ายในการโอน', value: data.transferFee ? 'THB ' + data.transferFee.toLocaleString() : '-' },
        { label: 'ค่าปากถุง', value: data.frontManFee ? 'THB ' + data.frontManFee.toLocaleString() : '-' },
        { label: 'ดอกเบี้ยล่วงหน้า', value: `${data.advancedInterest} เดือน` || '-' },
    ]

    return (
        <Spin spinning={loading} size="large" style={{ marginTop: '2em' }}>
            <Typography.Title level={4} style={{ margin: 0, fontWeight: 700 }}>รายละเอียดสัญญา</Typography.Title>
            <Row style={{ marginTop: '15px' }}>
                {detailRows.map(({ label, value }, index) => (
                    <React.Fragment key={index}>
                        <Col span={8}>{label}</Col>
                        <Col span={16} style={{ textAlign: 'right', fontWeight: 'bold' }}>{value}</Col>
                        {index < detailRows.length - 1 && <Divider style={{ margin: '1em 0' }} />}
                    </React.Fragment>
                ))}
            </Row>
            <Divider style={{ margin: '1em 0' }} />
            <div style={{ marginBottom: '1em' }}>
                <span style={{ fontWeight: 600 }}>ราคาประเมิน :</span>
                <div
                    className="contentCenter"
                    style={{
                        color: '#154A98',
                        fontWeight: 600,
                        border: '2px solid #154A98',
                        padding: '6px',
                        borderRadius: '5px',
                        fontSize: '17px'
                    }}
                >
                    {data.estimatedAmount ? 'THB ' + data.estimatedAmount.toLocaleString() : '-'}
                </div>
            </div>
            <div>
                <span style={{ fontWeight: 600 }}>จำนวนเงินขายฝาก / จำนอง :</span>
                <div
                    className="contentCenter"
                    style={{
                        color: '#EE1723',
                        fontWeight: 600,
                        border: '2px solid #EE1723',
                        padding: '6px',
                        borderRadius: '5px',
                        fontSize: '17px'
                    }}
                >
                    {data.approvedAmount ? 'THB ' + data.approvedAmount.toLocaleString() : '-'}
                </div>
            </div>
            <Button
                block
                style={{ backgroundColor: '#0A52BD', color: 'white', fontWeight: 600, marginTop: '1em' }}
                onClick={() => navigate('/document', { state: { contractId: propertyId } })}
            >
                ดูเอกสาร
            </Button>
        </Spin>
    )
}

export default TabContractDetail
