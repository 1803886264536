import React from 'react'
import {Button} from 'antd-mobile'
import {useNavigate} from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <div className="center">
            <h1>404 Page Not Found</h1>
            <h3 className='text-center'>Sorry, the page you visited does not exist.</h3>
            <Button
                className='mt-[20px]'
                color="primary"
                onClick={() => {
                    navigate('/')
                }}
            >
                Back to Home
            </Button>
        </div>
    )
}

export default NotFound
