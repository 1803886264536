import React from 'react'
import {useNavigate} from 'react-router-dom'
import {CheckCircleFilled} from '@ant-design/icons'
import {Button, Modal} from 'antd-mobile'

interface ModalSendProofSuccessProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const ModalSendProofSuccess: React.FC<ModalSendProofSuccessProps> = ({visible, setVisible}) => {
    const navigate = useNavigate()

    return (
        <>
            <Modal
                header={<CheckCircleFilled className="text-[64px] text-[#4EC47F]"/>}
                title="ส่งข้อมูลสำเร็จ"
                visible={visible}
                content={
                    <Button
                        fill="outline"
                        color="primary"
                        className="w-[100%]"
                        onClick={() => {
                            setVisible(false)
                            navigate('/sendProof', {
                                state: {
                                    tab: 'รอดำเนินการ',
                                },
                                replace: true
                            })
                        }}
                    >
                        ดูข้อมูล
                    </Button>
                }
            />
        </>
    )
}

export default ModalSendProofSuccess
