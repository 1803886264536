import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {datadogRum} from '@datadog/browser-rum'
import liff from '@line/liff'

if (process.env.REACT_APP_DATADOG_ENV) {
    datadogRum.init({
        applicationId: '5c5cb760-4aab-4925-a76e-633b86abb2e9',
        clientToken: 'pub322c30fb2217303ede8b7ff4f0d26de3',
        site: 'datadoghq.com',
        service: 'consignex-borrower',
        env: process.env.REACT_APP_DATADOG_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })
}

const startApp = async () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    )
    try {
        if (process.env.REACT_APP_ENV === 'production') {
            await liff.init({
                liffId: process.env.REACT_APP_LINE_LIFF_ID || ''
            })
        }
    } catch (e) {
        // alert(`LIFF error: ${(e as Error).message}`)
    }
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
    )
}

startApp()
reportWebVitals()
