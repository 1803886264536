import React from 'react'
import {List, Tag} from 'antd-mobile'
import Phone from '../../svg/contact/Phone.svg'
import Mail from '../../svg/contact/Mail.svg'
import NavbarComponent from '../../components/Navbar'

const Contact = () => {

    return (
        <div className="backgroundPage">
            <NavbarComponent title="ติดต่อเรา" back/>
            <div className="backgroundContent">
                <List style={{'--border-top': 'none'}}>
                    <List.Item
                        prefix={<img src={Mail} alt="mail" height={26} width={26}/>}
                        description="fin4u2019@gmail.com"
                        extra={
                            <Tag
                                color="#2977FF"
                                fill="outline"
                                className='text-[14px] border-[1px] py-[3px] px-[10px] bg-[#E5EFFF]'
                                onClick={() => window.open('mailto:fin4u2019@gmail.com')}
                            >
                                ส่งอีเมล
                            </Tag>
                        }
                    >
                        <div className='font-semibold'>Email</div>
                    </List.Item>
                    <List.Item
                        prefix={<img src={Phone} alt="phone" height={26} width={26}/>}
                        description="061 895 4469"
                        extra={
                            <Tag
                                color="#2977FF"
                                fill="outline"
                                className='text-[14px] border-[1px] py-[3px] px-[10px] bg-[#E5EFFF]'
                                onClick={() => window.open('tel:0618954469')}
                            >
                                โทรออก
                            </Tag>
                        }
                    >
                        <div className='font-semibold'>Phone</div>
                    </List.Item>
                </List>
            </div>
        </div>
    )
}

export default Contact
