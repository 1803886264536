import React, {useEffect, useState} from 'react'
import {Button, Form, Input, InputNumber, Select} from 'antd'
import ModalRegisterSuccess from '../proof/modals/ModalRegisterSuccess'
import AxiosConsigneX from '../../config/AxiosConfig'
import province from '../../province.json'
import NavbarComponent from '../../components/Navbar'
import {investorRefferalOptionNoContract} from '../../util/helper'

const Register = () => {
    const [form] = Form.useForm()
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        form.resetFields()
    }, [form])

    const onFinish = async (values: any) => {
        setLoading(true)
        try {
            await AxiosConsigneX.post('/register', {
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                assetType: values.assetType,
                province: values.province,
                amount: values.amount,
                contactTime: values.contactTime,
                referral: values.referral,
                note: values.note,
            })
            setModalVisible(true)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    return (
        <div className="backgroundPage">
            <ModalRegisterSuccess visible={modalVisible} setVisible={setModalVisible}/>
            <NavbarComponent title="ลงทะเบียนขายฝาก" back/>
            <div className="backgroundContent">
                <Form
                    form={form}
                    layout="vertical"
                    name="register"
                    onFinish={onFinish}
                    className='font-bold'
                >
                    <Form.Item label="ชื่อ" name="firstName" rules={[{required: true, message: 'กรุณากรอกชื่อ'}]}>
                        <Input placeholder="กรอกชื่อ"/>
                    </Form.Item>
                    <Form.Item label="นามสกุล" name="lastName" rules={[{required: true, message: 'กรุณากรอกนามสกุล'}]}>
                        <Input placeholder="กรอกนามสกุล"/>
                    </Form.Item>
                    <Form.Item
                        label="เบอร์โทรศัพท์"
                        name="phoneNumber"
                        rules={[{required: true, message: 'กรุณากรอกเบอร์โทรศัพท์'}]}
                    >
                        <Input placeholder="กรอกเบอร์โทรศัพท์" inputMode="numeric"/>
                    </Form.Item>
                    <Form.Item
                        label="ประเภททรัพย์"
                        name="assetType"
                        rules={[{required: true, message: 'กรุณากรอกประเภททรัพย์'}]}
                    >
                        <Input placeholder="กรอกประเภททรัพย์"/>
                    </Form.Item>
                    <Form.Item
                        label="จังหวัดที่ตั้งทรัพย์"
                        name="province"
                        rules={[{required: true, message: 'กรุณาเลือกจังหวัด'}]}
                    >
                        <Select placeholder="เลือกจังหวัด" className='font-medium'>
                            {province.map((item) => (
                                <Select.Option key={item.name_th} value={item.name_th}>
                                    {item.name_th}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="ยอดเงินที่ต้องการขายฝาก - จำนอง"
                        name="amount"
                        rules={[{required: true, message: 'กรุณากรอกยอดเงิน'}]}
                    >
                        <InputNumber
                            placeholder="กรอกยอดเงิน"
                            inputMode="numeric"
                            className='w-[100%]'
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    </Form.Item>
                    <Form.Item
                        label="ช่วงเวลาที่สะดวกให้ติดต่อกลับ"
                        name="contactTime"
                        rules={[{required: true, message: 'กรุณากรอกเวลาที่สะดวกให้ติดต่อกลับ'}]}
                    >
                        <Input placeholder="กรอกเวลา"/>
                    </Form.Item>
                    <Form.Item label="รู้จักโค้ชเต๊ะผ่านช่องทางใด (Referral)" name="referral">
                        <Select
                            className='formSelectDropdown'
                            placeholder={'กรุณาเลือก'}
                            options={investorRefferalOptionNoContract()}
                        />
                    </Form.Item>
                    <Form.Item label="เพิ่มเติม" name="note">
                        <Input.TextArea placeholder="กรอกข้อความ" rows={4}/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            className='bg-[#0A52BD]'
                            loading={loading}
                        >
                            ลงทะเบียน
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Register
