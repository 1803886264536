import React, {useEffect, useState} from 'react'
import {Avatar, Card, Image, List} from 'antd-mobile'
import {AddCircleOutline, ExclamationCircleOutline, TagOutline} from 'antd-mobile-icons'
import {Outlet, useNavigate} from 'react-router-dom'
import {Button, Skeleton} from 'antd'
import consigneXLogo from '../../svg/additional/logo.webp'
import liff from '@line/liff'

const Additional = () => {
    const navigate = useNavigate()
    const [pictureUrl, setPictureUrl] = useState<string>(consigneXLogo)
    const [loading, setLoading] = useState<boolean>(false)
    const phoneNumber = localStorage.getItem('phoneNumber')
    const [displayName, setDisplayName] = useState(phoneNumber)

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        setLoading(true)
        if (process.env.REACT_APP_ENV === 'production' && liff.isLoggedIn()) {
            try {
                const profile = await liff.getProfile()
                setDisplayName(profile.displayName)
                setPictureUrl(profile.pictureUrl || '')
            } catch (e) {
                // handle interceptor
            }
        } else {
            // handle in interceptor
        }
        setLoading(false)
    }

    const handleChange = (path: string) => {
        navigate(`/additional/${path}`)
    }

    const handleLogout = () => {
        if (process.env.REACT_APP_ENV === 'production') {
            if (liff.isLoggedIn()) {
                liff.logout()
            }
            localStorage.clear()
            navigate('/login')
        } else {
            localStorage.clear()
            navigate('/login')
        }
    }

    return (
        <div className="backgroundAdditional" style={{padding: '10px'}}>
            <div className='text-xl' style={{textAlign: 'center', color: 'white', margin: '17px 0px'}}>เมนูเพิ่มเติม
            </div>
            <div className="contentCenter">
                <Card className="cardStyleShadow w-[100%]">
                    <Skeleton active loading={loading} paragraph={{rows: 2}}>
                        <div style={{textAlign: 'center', marginTop: '6px'}}>
                            <Avatar
                                src={pictureUrl}
                                style={{borderRadius: '50%', '--size': '62px', margin: 'auto'}}
                            />
                            <h2 className='font-semibold'
                                style={{marginTop: '15px', fontSize: '20px'}}>{displayName}</h2>
                        </div>
                    </Skeleton>
                </Card>
            </div>
            <div>
                <div className='text-xl font-bold ml-[1em] mb-0 mt-[16px]'>เพิ่มเติม</div>
                <List mode="card">
                    <List.Item
                        className='font-medium'
                        prefix={<AddCircleOutline color="red"/>}
                        onClick={() => handleChange('register')}
                    >
                        ลงทะเบียนขายฝาก
                    </List.Item>
                    <List.Item
                        className='font-medium'
                        prefix={<TagOutline color="red"/>}
                        onClick={() => handleChange('account')}
                    >
                        บันทึกเลขบัญชี
                    </List.Item>
                    <List.Item
                        className='font-medium'
                        prefix={<ExclamationCircleOutline color="red"/>}
                        onClick={() => handleChange('contact')}
                    >
                        ติดต่อเรา
                    </List.Item>
                </List>
            </div>
            <div>
                <div className='text-xl font-bold ml-[1em] mb-0 mt-[16px]'>Social Media</div>
                <List mode="card">
                    <List.Item
                        className='font-medium'
                        prefix={
                            <Image
                                src={'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png'}
                                height={30}
                                width={30}
                                className='rounded-[50%]'
                            />
                        }
                        onClick={() => {
                            window.location.href = 'https://www.facebook.com/kaifakbyCoachTae/'
                        }}
                    >
                        รับขายฝาก-จำนอง by โค้ชเต๊ะ
                    </List.Item>
                    <List.Item
                        className='font-medium'
                        prefix={
                            <Image
                                src={'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/tiktok-square-icon.png'}
                                height={30}
                                width={30}
                                className='rounded-[50%]'
                            />
                        }
                        onClick={() => {
                            window.location.href = 'https://www.tiktok.com/@coachtae.ptw?_t=8hj8OIpIcPC&_r=1'
                        }}
                    >
                        รับขายฝาก-จำนอง by โค้ชเต๊ะ
                    </List.Item>
                </List>
                <Button
                    className='border-[2px] border-red-500 text-red-500 font-bold mt-40'
                    block
                    size='large'
                    onClick={handleLogout}
                >
                    ออกจากระบบ
                </Button>
            </div>
            <Outlet/>
        </div>
    )
}

export default Additional
