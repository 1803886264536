import React from 'react'
import {TabBar} from 'antd-mobile'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {FileTextOutlined, HomeOutlined, MenuOutlined, UnorderedListOutlined} from '@ant-design/icons'

const TabBarComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const tabs = [
        {
            title: 'หน้าหลัก',
            key: '/',
            icon: <HomeOutlined/>
        },
        {
            title: 'รายการ',
            key: '/listInterest',
            icon: <UnorderedListOutlined/>
        },
        {
            title: 'เอกสาร',
            key: '/document',
            icon: <FileTextOutlined/>
        },
        {
            title: 'เพิ่มเติม',
            key: '/additional',
            icon: <MenuOutlined/>
        }
    ]

    const activePathParts = params.tab ? params.tab.split('/') : location.pathname.split('/')

    const activeTab = tabs.find((item) => {
        const tabKeyParts = item.key.split('/')
        return activePathParts[1] === tabKeyParts[1]
    })

    return (
        <TabBar activeKey={activeTab ? activeTab.key : null} onChange={(key) => navigate(key)}>
            {tabs.map(item => (
                <TabBar.Item
                    key={item.key}
                    title={item.title}
                    icon={item.icon}
                    className="text-[#868686] mt-[2px]"
                />
            ))}
        </TabBar>
    )
}

export default TabBarComponent
