import React from 'react'
import './App.css'
import {createBrowserRouter, redirect, RouterProvider} from 'react-router-dom'
import Home from './pages/Home'
import ListInterest from './pages/ListInterest'
import Layout from './components/Layout'
import NotFound from './pages/PageNotFound'
import Document from './pages/document/Document'
import Additional from './pages/additional/Additional'
import Register from './pages/additional/Register'
import BankAccount from './pages/additional/bankAccount/BankAccount'
import Contact from './pages/additional/Contact'
import SendProof from './pages/proof/SendProof'
import ListFolder from './pages/document/ListFolder'
import ListFile from './pages/document/ListFile'
import Property from './pages/property/Property'
import ShowFile from './pages/document/ShowFile'
import ShowImages from './pages/property/ShowImages'
import SendProofConfirmPage from './pages/proof/SendProofConfirmPage'
import Login from './pages/Login'
import OTPVerify from './pages/OTPVerify'
import AxiosConsigneX from './config/AxiosConfig'
import 'maplibre-gl/dist/maplibre-gl.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require('dayjs')
dayjs().format()
dayjs.locale('th')

const App = () => {
    const router = createBrowserRouter([
        {
            path: '/login',
            element: <Login/>
        },
        {
            path: '/otpVerify',
            element: <OTPVerify/>
        },
        {
            path: '/',
            element: <Layout/>,
            loader: async () => {
                const token = localStorage.getItem('debtorToken')
                if (token) {
                    try {
                        const response = await AxiosConsigneX.get('/validateToken?token=' + token)
                        if (response.status === 200) {
                            return null
                        } else {
                            return redirect('/login')
                        }
                    } catch (error) {
                        return redirect('/login')
                    }
                }
                return redirect('/login')
            },
            children: [
                {
                    index: true,
                    element: <Home/>
                },
                {
                    path: 'listInterest',
                    element: <ListInterest/>
                },
                {
                    path: 'document',
                    element: <Document/>,
                    children: [
                        {
                            index: true,
                            element: <ListFolder/>
                        },
                        {
                            path: ':folderName/:contractId',
                            element: <ListFile/>
                        },
                    ]
                },
                {
                    path: 'document/:folderName/:contractId/:fileId',
                    element: <ShowFile/>
                },
                {
                    path: 'additional',
                    children: [
                        {
                            index: true,
                            element: <Additional/>
                        },
                        {
                            path: 'register',
                            element: <Register/>
                        },
                        {
                            path: 'account',
                            element: <BankAccount/>
                        },
                        {
                            path: 'contact',
                            element: <Contact/>
                        }
                    ]
                },
                {
                    path: 'property/:propertyId',
                    element: <Property/>,
                },
                {
                    path: 'property/:propertyId/images',
                    element: <ShowImages/>,
                },
                {
                    path: 'sendProof',
                    children: [
                        {
                            index: true,
                            element: <SendProof/>
                        },
                        {
                            path: 'confirm',
                            element: <SendProofConfirmPage/>
                        }
                    ]
                }
            ]
        },
        {
            path: '/notFound',
            element: <NotFound/>
        },
        {
            path: '*',
            loader: () => {
                return redirect('/notFound')
            },
        }
    ])

    return <RouterProvider router={router}/>
}

export default App
