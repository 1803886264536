import React, {useEffect, useState} from 'react'
import {ConfigProvider, Empty, Segmented, Select, Skeleton, Spin} from 'antd'
import {List, Tag} from 'antd-mobile'
import {UploadOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'
import AxiosConsigneX from '../config/AxiosConfig'
import NavbarComponent from '../components/Navbar'
import {formatDate} from '../util/helper'

const ListInterest = () => {
    const [tab, setTab] = useState<string | number>('ยังไม่ได้ชำระ')
    const navigate = useNavigate()
    const [contractData, setContractData] = useState<any>([])
    const [contractLoading, setContractLoading] = useState<boolean>(false)
    const [selectedContract, setSelectedContract] = useState<any>(null)
    const [interestData, setInterestData] = useState<any>([])
    const [interestLoading, setInterestLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchContract()
    }, [])

    useEffect(() => {
        if (contractData && contractData.length > 0) {
            setSelectedContract(contractData[0].id)
            fetchInterest(contractData[0].id, tab)
        } else {
            setSelectedContract('ไม่มีสัญญา')
        }
    }, [contractData])

    useEffect(() => {
        if (selectedContract) {
            fetchInterest(selectedContract, tab)
        }
    }, [tab])

    const fetchContract = async () => {
        setContractLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract')
            setContractData(data)
        } catch (e) {
            // handle in interceptor
        }
        setContractLoading(false)
    }

    const fetchInterest = async (contractId: string, selectedTab: string | number) => {
        setInterestLoading(true)
        try {
            if (selectedTab === 'ยังไม่ได้ชำระ') {
                const {data} = await AxiosConsigneX.get('/contract/contractPayment/status', {
                    params: {
                        contractId: contractId,
                        status: 'UnPaid'
                    }
                })
                setInterestData(data)
            } else if (selectedTab === 'ค้างชำระ') {
                const {data} = await AxiosConsigneX.get('/contract/contractPayment/status', {
                    params: {
                        contractId: contractId,
                        status: 'OverDue'
                    }
                })
                setInterestData(data)
            } else if (selectedTab === 'ชำระเงินแล้ว') {
                const {data} = await AxiosConsigneX.get('/contract/contractPayment/status', {
                    params: {
                        contractId: contractId,
                        status: 'Paid'
                    }
                })
                setInterestData(data)
            }
        } catch (e) {
            setInterestData([])
        }
        setInterestLoading(false)
    }

    const contract = contractData.map((item: any) => ({
        label: 'สัญญา' + item.transactionType + ' : ' + item.id,
        value: item.id,
    }))

    const handleChange = (value: any) => {
        setSelectedContract(value)
        const chooseDataTransactionType = contractData.find((item: any) => item.id === value).transactionType
        fetchInterest(value, tab)
        localStorage.setItem('transactionType', chooseDataTransactionType)
        localStorage.setItem('contractId', value)
    }

    return (
        <div className="backgroundPage relative">
            <NavbarComponent title="รายการชำระดอกเบี้ย" back/>
            <UploadOutlined
                className="text-white text-[24px] absolute right-[15px] top-[15px]"
                onClick={() => {
                    navigate('/sendProof', {
                        state: {
                            tab: 'แจ้งหลักฐาน'
                        }
                    })
                }}
            />
            <ConfigProvider
                theme={{
                    components: {
                        Segmented: {
                            itemHoverColor: '#FD414C',
                            colorBgLayout: '#FFD6D6',
                            itemColor: '#FD414C',
                            itemSelectedColor: '#FD414C',
                        }
                    }
                }}
            >
                <div className="pt-0 px-[1.5em] pb-[1.25em]">
                    <Segmented
                        block
                        size="large"
                        options={['ยังไม่ได้ชำระ', 'ค้างชำระ', 'ชำระเงินแล้ว']}
                        value={tab}
                        onChange={setTab}
                    />
                </div>
            </ConfigProvider>
            <div className="backgroundContent">
                <Spin spinning={contractLoading} size="large">
                    <Select
                        options={contract}
                        loading={contractLoading}
                        disabled={contractLoading || contract.length === 0}
                        value={selectedContract}
                        onChange={handleChange}
                        className="w-[100%]"
                    />
                    <Skeleton loading={interestLoading} active>
                        {interestData && interestData.length > 0 ? (
                            <List style={{'--border-top': 'none', '--padding-left': '0', '--padding-right': '0'}}>
                                {interestData.map((item: any, index: number) => (
                                    <List.Item
                                        key={index}
                                        onClick={() => tab === 'ค้างชำระ' ? navigate('/sendProof', {
                                            state: {
                                                tab: 'แจ้งหลักฐาน',
                                                round: item.round,
                                                status: 'OverDue'
                                            },
                                            replace: true
                                        }) : null}
                                        title={
                                            <span className="text-[15px] font-bold text-black">
                                                {item.month}
                                            </span>
                                        }
                                        description={
                                            <span className="text-[13px]">
                                                {'งวดที่ ' + item.round + ' | ครบกำหนด ' + formatDate(item.dueDate)}
                                            </span>
                                        }
                                        extra={'THB ' + Math.trunc((item.amount * 100) / 100).toLocaleString()}
                                    >
                                        <div>
                                            <Tag
                                                color={tab === 'ยังไม่ได้ชำระ' ? '#FF6700' : tab === 'ค้างชำระ' ? '#FF4D4F' : '#14B724'}
                                                fill="outline"
                                                style={{'--background-color': tab === 'ยังไม่ได้ชำระ' ? '#FFD7B5' : tab === 'ค้างชำระ' ? '#FFE4E5' : '#E9FFCC'}}
                                                className="mb-[0.5em]"
                                            >
                                                {tab === 'ยังไม่ได้ชำระ' ? 'ยังไม่ได้ชำระ' : tab === 'ค้างชำระ' ? 'ค้างชำระ' : 'ชำระแล้ว'}
                                            </Tag>
                                        </div>
                                    </List.Item>
                                ))}
                            </List>
                        ) : (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                className="mt-[2em]"
                            />
                        )}
                    </Skeleton>
                </Spin>
            </div>
        </div>
    )
}

export default ListInterest
