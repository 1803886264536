import React, {useEffect, useState} from 'react'
import {Col, Modal, Row} from 'antd'
import Detail from '../../../svg/proof/Detail.svg'
import {Image, ImageViewer} from 'antd-mobile'
import {EyeOutlined} from '@ant-design/icons'
import {formatDate, formatTime} from '../../../util/helper'
import AxiosConsigneX from '../../../config/AxiosConfig'

interface ModalApproveProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    dataPayment: any;
}

interface Bank {
    bankName: string;
    logo: string | null;
}

const ModalInfoView: React.FC<ModalApproveProps> = ({visible, setVisible, dataPayment}) => {
    const contractId = localStorage.getItem('contractId')
    const transactionType = localStorage.getItem('transactionType')
    const [bankImage, setBankImage] = useState<Bank[]>([])

    useEffect(() => {
        fetchBankLogo()
    }, [dataPayment])

    const fetchBankLogo = async () => {
        try {
            const {data} = await AxiosConsigneX.get('/contract/getBankImage', {
                params: {
                    bank: dataPayment.account.bank
                }
            })
            setBankImage(data)
        } catch (error) {
            // handle in interceptor
        }
    }

    return (
        <>
            <Modal
                title="รายละเอียดหลักฐานการชำระเงิน"
                footer={null}
                open={visible}
                onCancel={() => setVisible(false)}
            >
                <div
                    className="border-[1px] border-solid border-[#d9d9d9], rounded-[5px] py-[5px] px-[1em] font-semibold"
                >
                    สัญญา{transactionType} : {contractId}
                </div>
                <div className="contentCenter mt-[1em]">
                    <img src={Detail} alt="detail"/>
                </div>
                <Row className="mt-[1em]">
                    <Col span={12}>
                        <div className="my-[10px]">งวดที่ชำระดอกเบี้ย</div>
                        <div className="my-[10px]">ยอดเงินที่โอน</div>
                        <div className="my-[10px]">วันที่โอนเงิน</div>
                        <div className="my-[10px]">เวลาที่โอนเงิน</div>
                    </Col>
                    <Col span={12} className="text-right font-semibold">
                        <div className="my-[10px]">
                            {`${dataPayment?.round}/${dataPayment?.allRound} ${dataPayment?.month} ${dataPayment?.year}` || '-'}
                        </div>
                        <div className="my-[10px]">
                            {dataPayment.amount ? `${dataPayment.amount.toLocaleString()} บาท` : '-'}
                        </div>
                        <div className="my-[10px]">{formatDate(dataPayment.transactionDate || '-')}</div>
                        <div className="my-[10px]">{formatTime(dataPayment.transactionDate || '-')}</div>
                    </Col>
                </Row>
                {dataPayment.account &&
                    <div
                        className="spaceBetween p-[1em] rounded-[5px] border-[1px] border-solid border-[#d9d9d9]"
                    >
                        <div>
                            <div>{dataPayment.account.type == 'พร้อมเพย์' ? 'บัญชี' : 'ธนาคาร'}{dataPayment.account.bank}</div>
                            <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                ชื่อบัญชี : {dataPayment.account.name || '-'}
                            </div>
                            <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                เลขที่บัญชี : {dataPayment.account.accountNumber || '-'}
                            </div>
                        </div>
                        <Image
                            src={bankImage[0]?.logo || ''}
                            height={50}
                            width={50}
                            className="ml-[2em] rounded-[10%]"
                        />
                    </div>
                }
                {dataPayment.image &&
                    <div
                        className="spaceBetween p-[1em] rounded-[5px] border-[1px] border-solid border-[#d9d9d9] mt-[1em]"
                    >
                        <div className="contentCenter">
                            <Image
                                src={dataPayment.image}
                                alt="image"
                                height={40}
                                width={40}
                                className='mr-[1em]'
                                fit="cover"
                            />
                            <div style={{fontWeight: 500}}>{dataPayment.imageName}</div>
                        </div>
                        <EyeOutlined
                            className='text-[20px]'
                            onClick={() => {
                                ImageViewer.show({
                                    image: dataPayment.image,
                                })
                            }}
                        />
                    </div>
                }
            </Modal>
        </>
    )
}

export default ModalInfoView
