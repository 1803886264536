import React, {useEffect, useState} from 'react'
import {List} from 'antd-mobile'
import PdfIcon from '../../svg/document/Pdf.svg'
import {useNavigate, useParams} from 'react-router-dom'
import {Empty, Skeleton} from 'antd'
import AxiosConsigneX from '../../config/AxiosConfig'
import JpegImage from '../../svg/document/JpegImage.svg'

const ListFile = () => {
    const params: any = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [listFiles, setListFiles] = useState<any>([])

    useEffect(() => {
        getListFile()
    }, [])

    const getListFile = async () => {
        setLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/listFileContractDebtor', {
                params: {
                    contractId: decodeURIComponent(params.contractId),
                    documentType: params.folderName
                }
            })
            setListFiles(data)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    const handleFileClick = (item: any) => {
        navigate(`/document/${params.folderName}/${encodeURIComponent(params.contractId)}/${item.fileId}`)
    }

    return (
        <>
            <Skeleton loading={loading} active>
                {listFiles.length !== 0 ? (
                    <List style={{'--border-top': 'none', '--padding-left': '0', '--padding-right': '0'}}>
                        {listFiles.map((item: any, index: number) => (
                            <List.Item
                                key={index}
                                prefix={
                                    <div
                                        className={`flex rounded-[6px] p-[8px] my-[8px] mx-[0px] ${item?.type === 'pdf' ? 'bg-[#FFE7E7]' : 'bg-[#C0E1F9]'}`}>
                                        {
                                            <img
                                                src={item?.type === 'pdf' ? PdfIcon : JpegImage}
                                                alt="icon"
                                                width={30}
                                                height={30}
                                            />
                                        }
                                    </div>
                                }
                                onClick={() => handleFileClick(item)}
                            >
                                <div className="font-semibold text-[13px]">{item.title}</div>
                            </List.Item>
                        ))}
                    </List>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="mt-[2em]"/>
                )}
            </Skeleton>
        </>
    )
}

export default ListFile
