import React, {useEffect, useRef, useState} from 'react'
import {Image, PageIndicator, Swiper} from 'antd-mobile'
import {Col, Divider, Row, Spin, Statistic, Typography} from 'antd'
import Price from '../../../svg/property/Price.svg'
import Money from '../../../svg/property/Money.svg'
import Location from '../../../svg/home/Location.svg'
import {useNavigate, useParams} from 'react-router-dom'
import AxiosConsigneX from '../../../config/AxiosConfig'
import {formatAssetType} from '../../../util/helper'
import PreviewMap from '../../../components/Map/PreviewMap'
import {MapRef} from 'react-map-gl'

const TabAssetDetail: React.FC = () => {
    const navigate = useNavigate()
    const {propertyId} = useParams()
    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const mapRef = useRef<MapRef | null>(null)
    const [geoJson, setGeoJson] = useState({
        'type': 'FeatureCollection',
        'features': {}
    })

    useEffect(() => {
        fetchAsset()
    }, [])

    const fetchAsset = async () => {
        setLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/asset/assetDetail', {
                params: {
                    contractId: decodeURIComponent(propertyId!)
                }
            })
            setData(data)
            const newGeoJson = {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [data.longitude, data.latitude],
                        }
                    }
                ]
            }
            setGeoJson(newGeoJson)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    return (
        <>
            <Spin spinning={loading} size="large" className="mt-[2em]">
                {data.image ? (
                    <Swiper
                        indicator={(total, current) => (
                            <>
                                <div className="number-indicator">
                                    {`${current + 1}/${total}`}
                                </div>
                                <PageIndicator
                                    total={total}
                                    current={current}
                                    color="white"
                                    className="page-indicator"
                                    style={{
                                        '--dot-size': '6px',
                                        '--dot-border-radius': '50%',
                                        '--active-dot-size': '30px',
                                        '--active-dot-border-radius': '15px',
                                    }}
                                />
                            </>
                        )}
                    >
                        {data.image.map((item: string, index: number) => (
                            <Swiper.Item key={index}>
                                <div className="contentCenter">
                                    <Image
                                        src={item}
                                        fit="cover"
                                        height="30vh"
                                        width="100%"
                                        className="rounded-[2rem]"
                                        onClick={() =>
                                            navigate(`/property/${encodeURIComponent(propertyId!)}/images`)
                                        }
                                    />
                                </div>
                            </Swiper.Item>
                        ))}
                    </Swiper>
                ) : (
                    <div className="h-[30vh] bg-[#E5E5E5] rounded-[2rem]"/>
                )}
                <Typography.Title level={4} className="mt-[0.75em]">{data.assetName || '-'}</Typography.Title>
                <Row>
                    <Col span={10}>
                        <Statistic
                            title={
                                <div className="flex items-center">
                                    <img src={Price} alt="price" height={20} width={20} className="me-[4px]"/>
                                    <span className="text-[#878787] text-[15px]">มูลค่าหลักทรัพย์</span>
                                </div>
                            }
                            value={data.estimatedAmount}
                            valueStyle={{color: '#0D3C8E', fontWeight: 600, fontSize: 20}}
                            prefix={'฿'}
                        />
                    </Col>
                    <Col span={2} offset={1}>
                        <Divider type="vertical" className="h-[80%] border-[#DEDEDE]"/>
                    </Col>
                    <Col span={10} offset={1}>
                        <Statistic
                            title={
                                <div className="flex items-center">
                                    <img src={Money} alt="money" height={20} width={20} className="me-[4px]"/>
                                    <span className="text-[#878787] text-[15px]">อนุมัติราคา</span>
                                </div>
                            }
                            value={data.approvedAmount}
                            valueStyle={{color: '#FF4D4F', fontWeight: 600, fontSize: 20}}
                            prefix={'฿'}
                        />
                    </Col>
                </Row>
                <Divider className="my-[1em] mx-0"/>
                <Typography.Title level={5} className="mt-[0.75em]">รายละเอียดทรัพย์</Typography.Title>
                <Row className="py-0 px-[1em]">
                    <Col span={8} className="text-[15px] font-medium leading-7">
                        <div>หมายเลขทรัพย์</div>
                        <div>ประเภทธุรกรรม</div>
                        <div>ประเภททรัพย์</div>
                        <div>เนื้อที่</div>
                        <div>ราคาประเมิน</div>
                        <div>อนุมัติราคา</div>
                    </Col>
                    <Col span={16} className="text-right text-[15px] font-semibold leading-7">
                        <div>{data.assetId || '-'}</div>
                        <div>{data.transactionType || '-'}</div>
                        <div>{formatAssetType(data.assetType || '-')}</div>
                        <div>
                            {` ${data?.areaRai} ไร่`}
                            {` ${data?.areaNgan} งาน`}
                            {` ${data?.areaTaRangWa} ตร.ว`}
                            {` ${data?.areaSquareMeter} ตร.ม`}
                        </div>
                        <div>{data.estimatedAmount ? '฿ ' + data.estimatedAmount.toLocaleString() : '-'}</div>
                        <div>{data.approvedAmount ? '฿ ' + data.approvedAmount.toLocaleString() : '-'}</div>
                    </Col>
                </Row>
                <Divider className="my-[1em] mx-0"/>
                <Typography.Title level={5} className="mt-[0.75em]">ตำแหน่งที่ตั้ง</Typography.Title>
                <div className="flex mb-[30px]">
                    <img src={Location} alt="location" height={20} width={20}/>
                    <span style={{fontSize: 14, fontWeight: 500, marginLeft: '10px'}}>
                        {data.address && data.subDistrict && data.district && data.province ?
                            data.address + ' แขวง' + data.subDistrict + ' เขต' + data.district + ' ' + data.province
                            : '-'
                        }
                    </span>
                </div>
                {data.googleMapsLink &&
                    <PreviewMap
                        mapRef={mapRef}
                        geoJson={geoJson}
                    />
                }
            </Spin>
        </>
    )
}

export default TabAssetDetail
