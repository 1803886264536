import React, {useEffect, useState} from 'react'
import {ConfigProvider, DatePicker, Image, ImageViewer, Picker, Radio} from 'antd-mobile'
import {Button, Card, Empty, Form, Input, InputNumber, Select, Skeleton, Space, Spin, Upload} from 'antd'
import {PictureOutlined} from '@ant-design/icons'
import enUS from 'antd-mobile/es/locales/en-US'
import {PickerValue} from 'antd-mobile/es/components/picker-view'
import {useLocation, useNavigate} from 'react-router-dom'
import AxiosConsigneX from '../../../config/AxiosConfig'
import {formatDate} from '../../../util/helper'

interface Bank {
    bankName: string;
    logo: string | null;
}

const TabSendProof = () => {
    const now = new Date()
    const [form] = Form.useForm()
    const [dateVisible, setDateVisible] = useState<boolean>(false)
    const [timeVisible, setTimeVisible] = useState<boolean>(false)
    const navigate = useNavigate()
    const [investorAccount, setInvestorAccount] = useState<any>([])
    const [investorAccountLoading, setInvestorAccountLoading] = useState<boolean>(false)
    const [transactionRound, setTransactionRound] = useState<any>([])
    const [transactionRoundLoading, setTransactionRoundLoading] = useState<boolean>(false)
    const transactionType = localStorage.getItem('transactionType')
    const contractId = localStorage.getItem('contractId')
    const [bankImage, setBankImage] = useState<Bank[]>([])
    const splitRound = Form.useWatch('round', form)
    const amountToPay = transactionRound?.find((item: { round: any }) => item?.round === splitRound)?.amount
    const nextDate = transactionRound?.find((item: { round: any }) => item?.round === splitRound)?.nextDate
    const location = useLocation()
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [isRoundReject, setIsRoundReject] = useState<boolean>(false)

    useEffect(() => {
        form.resetFields()
    }, [form])

    useEffect(() => {
        if (contractId) {
            form.setFieldsValue({
                contractId: contractId,
            })
            fetchInvestorAccount(contractId)
            fetchTransactionRound(contractId)
        } else {
            form.setFieldsValue({
                contractId: 'ไม่มีสัญญา',
            })
        }
    }, [contractId, form])

    useEffect(() => {
        if (transactionRound && transactionRound.length > 0) {
            form.setFieldsValue({
                round: transactionRound[0].round,
            })
        } else {
            form.setFieldsValue({
                round: 'ไม่มีงวดที่ชำระดอกเบี้ย',
            })
        }
    }, [transactionRound, form])

    useEffect(() => {
        if (investorAccount) {
            fetchBankLogo()
        }
    }, [investorAccount])

    const fetchBankLogo = async () => {
        try {
            const bankNames = investorAccount.map((item: { bank: any }) => item.bank)
            const {data} = await AxiosConsigneX.get('/contract/getBankImage', {
                params: {
                    bank: bankNames.join(',')
                }
            })
            setBankImage(data)
        } catch (error) {
            // handle in interceptor
        }
    }

    const fetchInvestorAccount = async (contractId: string) => {
        setInvestorAccountLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/investorAccount', {
                params: {
                    contractId: contractId
                }
            })
            setInvestorAccount(data)
        } catch (e) {
            // handle in interceptor
        }
        setInvestorAccountLoading(false)
    }

    const fetchTransactionRound = async (contractId: string) => {
        setTransactionRoundLoading(true)
        try {
            if (!location.state.round) {
                const {data} = await AxiosConsigneX.get('/contract/transactionRoundDebtor', {
                    params: {
                        contractId: contractId
                    }
                })
                setTransactionRound(data)
                setIsRoundReject(false)
            } else if (location.state.round && location?.state?.status == 'OverDue') {
                const {data} = await AxiosConsigneX.get('/contract/transactionRoundOverDueDebtor', {
                    params: {
                        contractId: contractId,
                        round: location.state.round
                    }
                })
                setTransactionRound(data)
                setIsRoundReject(false)
            } else {
                const {data} = await AxiosConsigneX.get('/contract/transactionRoundRejectedDebtor', {
                    params: {
                        contractId: contractId,
                        round: location.state.round
                    }
                })
                setTransactionRound(data)
                setIsRoundReject(true)
            }
        } catch (e) {
            // handle in interceptor
        }
        setTransactionRoundLoading(false)
    }

    const hours = Array.from({length: 24}, (_, index) => ({
        label: index.toString().padStart(2, '0'),
        value: index.toString().padStart(2, '0'),
    }))

    const minutes = Array.from({length: 60}, (_, index) => ({
        label: index.toString().padStart(2, '0'),
        value: index.toString().padStart(2, '0'),
    }))

    const transactionRoundOptions = transactionRound.map((item: any) => ({
        label: item.round + '/' + item.allRound + ' ' + item.month + ' ' + item.year,
        value: item.round,
    }))

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e?.fileList
    }

    const handleDateConfirm = (date: Date) => {
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).format(date).replace(/\//g, '-')
        form.setFieldsValue({
            date: formattedDate
        })
        setDateVisible(false)
    }

    const handleTimeConfirm = (value: PickerValue[]) => {
        form.setFieldsValue({
            time: `${value[0]}:${value[1]}`
        })
        setTimeVisible(false)
    }

    const handleSubmit = (values: any) => {
        navigate('/sendProof/confirm', {
            state: {
                ...values,
                accountData: investorAccount.filter((item: any) => item.accountNumber === values.account)[0],
                roundData: transactionRound.filter((item: any) => item.round === values.round)[0],
                transactionType: transactionType,
                bankImage: bankImage,
                reject: isRoundReject
            },
        })
    }

    return (
        <div>
            <Spin spinning={transactionRoundLoading} size="large">
                <ConfigProvider locale={enUS}>
                    <DatePicker
                        visible={dateVisible}
                        onClose={() => setDateVisible(false)}
                        defaultValue={now}
                        max={now}
                        precision="day"
                        onConfirm={handleDateConfirm}
                        confirmText="OK"
                    />
                    <Picker
                        visible={timeVisible}
                        onClose={() => setTimeVisible(false)}
                        columns={[hours, minutes]}
                        defaultValue={[now.getHours().toString().padStart(2, '0'), now.getMinutes().toString().padStart(2, '0')]}
                        onConfirm={handleTimeConfirm}
                        confirmText="OK"
                    />
                </ConfigProvider>
                <Form
                    form={form}
                    layout="vertical"
                    name="sendProof"
                    className="mt-[1em] font-semibold"
                    onFinish={handleSubmit}
                >
                    <Form.Item name="contractId" rules={[{required: true, message: 'กรุณาเลือกสัญญา'}]}>
                        <Select
                            options={contractId && contractId !== 'ไม่มีสัญญา' ? [{
                                label: 'สัญญา' + transactionType + ' : ' + contractId,
                                value: contractId
                            }] : []}
                            disabled
                        />
                    </Form.Item>
                    <Skeleton loading={transactionRoundLoading} active>
                        <h3>ข้อมูลหลักฐานการชำระเงิน</h3>
                        <Form.Item
                            label="งวดที่ชำระดอกเบี้ย"
                            name="round"
                            rules={[{required: true, message: 'กรุณาเลือกงวดที่ชำระดอกเบี้ย'}]}
                        >
                            <Select
                                options={transactionRoundOptions}
                                loading={transactionRoundLoading}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            label="ยอดเงินที่โอน"
                            name="transferAmount"
                            rules={[{required: true, message: 'กรุณากรอกยอดเงินที่โอน'}]}
                        >
                            <InputNumber
                                suffix="บาท"
                                placeholder="0.00"
                                className="font-normal w-[100%]"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                        <div className="text-right mb-[1em] mt-[-1em] font-light">
                            ยอดชำระ {amountToPay?.toLocaleString()} บาท วันที่ครบกำหนดจ่าย {formatDate(nextDate)}
                        </div>
                        <Form.Item
                            label="วันที่โอนเงิน"
                            name="date"
                            rules={[{required: true, message: 'กรุณาเลือกวันที่โอนเงิน'}]}
                        >
                            <Input readOnly onClick={() => setDateVisible(true)}/>
                        </Form.Item>
                        <Form.Item
                            label="เวลาที่โอนเงิน"
                            name="time"
                            rules={[{required: true, message: 'กรุณาเลือกเวลาที่โอนเงิน'}]}
                        >
                            <Input readOnly onClick={() => setTimeVisible(true)}/>
                        </Form.Item>
                        <Form.Item
                            label="ธนาคารที่โอนเงิน"
                            name="account"
                            rules={[{required: true, message: 'กรุณาเลือกบัญชีธนาคาร'}]}
                        >
                            {!investorAccountLoading ?
                                investorAccount.length > 0 ? (
                                    <Radio.Group>
                                        {investorAccount.map((item: any) => (
                                            <Radio
                                                key={item.id}
                                                value={item.accountNumber}
                                                className="border-[1px] border-solid border-[#d9d9d9] rounded-[5px] p-[1em] my-[1em] mx-0"
                                                block
                                            >
                                                <Space
                                                    direction="horizontal"
                                                    align="center"
                                                    size="large"
                                                    className="ml-[1em]"
                                                >
                                                    <Image
                                                        src={bankImage?.find((bank: any) => bank?.bankName === item.bank)?.logo || ''}
                                                        height={50}
                                                        width={50}
                                                        className="rounded-[10%]"
                                                    />
                                                    <div>
                                                        <div>
                                                            {item.bank === item.type ? 'บัญชี' + item.bank : 'ธนาคาร' + item.bank}
                                                        </div>
                                                        <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                                            ชื่อบัญชี : {item.name}
                                                        </div>
                                                        <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                                            เลขที่บัญชี : {item.accountNumber}
                                                        </div>
                                                    </div>
                                                </Space>
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                ) : (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        className="my-[1em] mx-[0px]"
                                        description="ไม่มีบัญชีธนาคาร"
                                    />
                                )
                                : <Skeleton active/>
                            }
                        </Form.Item>
                        {transactionRound && transactionRound.length > 0 && transactionRound[0].image && (
                            <>
                                <div className="mb-[1em]">หลักฐานการโอนเงินในระบบ</div>
                                <Card
                                    className="mb-[1em]"
                                    classNames={{body: 'flex items-center p-[1em]'}}
                                    onClick={() => setPreviewVisible(true)}
                                >
                                    <Image
                                        src={transactionRound[0].image.image}
                                        height={50}
                                        width={50}
                                    />
                                    <div className="ml-[1em]">{transactionRound[0].image.imageName}</div>
                                    <ImageViewer
                                        visible={previewVisible}
                                        image={transactionRound[0].image.image}
                                        onClose={() => setPreviewVisible(false)}
                                    />
                                </Card>
                            </>
                        )}
                        <Form.Item
                            label="แนบหลักฐานการโอนเงิน"
                            name="upload"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{required: true, message: 'กรุณาแนบหลักฐานการโอนเงิน'}]}
                        >
                            <Upload
                                listType="picture"
                                accept="image/*"
                                maxCount={1}
                                beforeUpload={(file => {
                                    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                                        return Upload.LIST_IGNORE
                                    }
                                    return false
                                })}
                            >
                                <div className="text-[#BFBFBF]">
                                    <p><PictureOutlined className="text-[50px]"/></p>
                                    <p>Tap for upload</p>
                                </div>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="หมายเหตุ (บันทึกช่วยจำ)"
                            name="note"
                            rules={[{required: false}]}
                        >
                            <Input placeholder="กรอกข้อความ..."/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="bg-[#0A52BD]"
                                size="large"
                                block
                            >
                                ถัดไป
                            </Button>
                        </Form.Item>
                    </Skeleton>
                </Form>
            </Spin>
        </div>
    )
}

export default TabSendProof
