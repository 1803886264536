import React, {useEffect, useState} from 'react'
import {Card, Empty, Select, Spin} from 'antd'
import {Image, Toast} from 'antd-mobile'
import Copy from '../../../svg/bankAccount/Copy.svg'
import AxiosConsigneX from '../../../config/AxiosConfig'
import NavbarComponent from '../../../components/Navbar'

interface Bank {
    bankName: string;
    logo: string | null;
}

const BankAccount = () => {
    const [contractLoading, setContractLoading] = useState<boolean>(false)
    const [accountLoading, setAccountLoading] = useState<boolean>(false)
    const [contractData, setContractData] = useState<any>([])
    const [accountData, setAccountData] = useState<any>([])
    const [selectedContract, setSelectedContract] = useState<any>(null)
    const [bankImage, setBankImage] = useState<Bank[]>([])

    useEffect(() => {
        fetchContract()
    }, [])

    useEffect(() => {
        if (contractData && contractData.length > 0) {
            fetchAccount(contractData[0].id)
            setSelectedContract(contractData[0].id)
        } else {
            setSelectedContract('ไม่มีสัญญา')
        }
    }, [contractData])

    useEffect(() => {
        fetchBankLogo()
    }, [accountData])

    const fetchBankLogo = async () => {
        try {
            const account = accountData.map((item: { bank: any }) => item.bank)
            const {data} = await AxiosConsigneX.get('/contract/getBankImage', {
                params: {
                    bank: account.join(',')
                }
            })
            setBankImage(data)
        } catch (error) {
            // handle in interceptor
        }
    }

    const fetchContract = async () => {
        setContractLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract')
            setContractData(data)
        } catch (e) {
            // handle in interceptor
        }
        setContractLoading(false)
    }

    const fetchAccount = async (contractId: string) => {
        setAccountLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/investorAccount', {
                params: {
                    contractId: contractId
                }
            })
            setAccountData(data)
        } catch (e) {
            // handle in interceptor
        }
        setAccountLoading(false)
    }

    const contract = contractData.map((item: any) => ({
        label: 'สัญญา' + item.transactionType + ' : ' + item.id,
        value: item.id,
    }))

    const handleChange = (value: any) => {
        setSelectedContract(value)
        fetchAccount(value)
    }

    const fallbackCopyTextToClipboard = (text: string) => {
        const textArea = document.createElement('textarea')
        textArea.value = text
        textArea.style.position = 'fixed'
        textArea.style.left = '-9999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
            const successful = document.execCommand('copy')
            const message = successful ? 'คัดลอกเลขบัญชีสำเร็จ' : 'คัดลอกเลขบัญชีไม่สำเร็จ'
            Toast.show({
                content: message,
                position: 'bottom',
            })
        } catch (err) {
            // handle interceptor
        }
        document.body.removeChild(textArea)
    }

    const handleCopyToClipboard = (text: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                Toast.show({
                    content: 'คัดลอกเลขบัญชีสำเร็จ',
                    position: 'bottom',
                })
            }).catch(() => {
                // If clipboard.writeText fails, use fallback mechanism
                fallbackCopyTextToClipboard(text)
            })
        } else {
            // Fallback for environments that do not support Clipboard API
            fallbackCopyTextToClipboard(text)
        }
    }

    return (
        <>
            <div className="backgroundPage">
                <NavbarComponent title="บันทึกเลขบัญชี" back/>
                <div className="backgroundContent">
                    <Spin spinning={accountLoading || contractLoading} size="large">
                        <Select
                            options={contract}
                            loading={contractLoading}
                            disabled={contractLoading || contractData.length === 0}
                            value={selectedContract}
                            onChange={handleChange}
                            className='w-[100%] mt-[1em]'
                        />
                        {accountData.length > 0 ?
                            accountData.map((item: any, index: any) => (
                                <Card key={index} className='w-[100%] mt-[1em]'>
                                    <div className='flex justify-center items-center'>
                                        <Image
                                            src={bankImage?.find((bank) => bank.bankName === item.bank)?.logo || ''}
                                            height={50}
                                            width={50}
                                            className='rounded-[10%]'
                                        />
                                        <div
                                            className='flex flex-col ml-[1em]'
                                        >
                                            <span className='font-semibold'>{item.name}</span>
                                            <span style={{fontWeight: 500, color: 'var(--adm-color-weak)'}}>
                                                {item.bank}
                                            </span>
                                            <span style={{fontWeight: 500, color: 'var(--adm-color-weak)'}}>
                                                {item.accountNumber}
                                            </span>
                                        </div>
                                        <img
                                            src={Copy}
                                            alt="copy"
                                            height={25}
                                            width={25}
                                            className='ml-auto'
                                            onClick={() => handleCopyToClipboard(item.accountNumber)}
                                        />
                                    </div>
                                </Card>
                            ))
                            :
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                className='mt-3em'
                                description={'No bank account'}
                            />
                        }
                    </Spin>
                </div>
            </div>
        </>
    )
}

export default BankAccount
