import React, {useState} from 'react'
import {Card, ResultPage} from 'antd-mobile'
import {Button, Form, Input, Statistic, Typography} from 'antd'
import {useNavigate} from 'react-router-dom'
import AxiosConsigneX from '../config/AxiosConfig'
import dayjs from 'dayjs'

const OTPVerify = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [showTimer, setShowTimer] = useState(false)
    const [deadLineTime, setDeadLineTime] = useState(0)
    const [requestOtpAgain, setRequestOtpAgain] = useState(false)
    const phoneNumberValue = Form.useWatch((values: { phoneNumber: any }) => values?.phoneNumber, form)
    const otpValue = Form.useWatch((values: { otp: any }) => values?.otp, form)

    const handleSubmit = async (values: any) => {
        try {
            const {data} = await AxiosConsigneX.post('/login', {
                phoneNumber: values.phoneNumber,
                otp: values.otp
            })
            localStorage.setItem('debtorToken', data.token)
            localStorage.setItem('phoneNumber', form.getFieldValue('phoneNumber'))
            navigate('/')
        } catch (e) {
            // handle in interceptor
        }
    }

    const handleOTPRequest = async () => {
        setRequestOtpAgain(false)
        try {
            const {data} = await AxiosConsigneX.post('/sentUserOtp', null, {
                params: {phoneNumber: phoneNumberValue}
            })
            const dateNow = dayjs()
            const expireTime = dayjs(data.expireTime)
            setDeadLineTime(dayjs().valueOf() + expireTime.diff(dateNow, 'millisecond'))
            setShowTimer(true)
        } catch (e) {
            // handle in interceptor
        }
    }

    return (
        <ResultPage
            title=''
            icon={
                <Typography.Text className="text-white text-[20px] font-bold mx-0 my-[0.5em]">
                    เข้าสู่ระบบ
                </Typography.Text>
            }
            style={{
                '--background-color': '#FD414C',
            }}
        >
            <Card className="cardStyleShadow">
                <div>
                    <div className="text-[16px] font-bold">กรอกข้อมูลเพื่อยืนยันตัวตน</div>
                    <div className="text-[13px]">* รองรับการใช้งานสำหรับบุคคลธรรมดาเท่านั้น</div>
                    <Form
                        form={form}
                        layout="vertical"
                        name="otpVerify"
                        onFinish={handleSubmit}
                        className="font-medium mt-[1em]"
                        requiredMark={false}
                    >
                        <Form.Item label="ระบุหมายเลขโทรศัพท์">
                            <div className="flex gap-[1em]">
                                <Form.Item
                                    name="phoneNumber"
                                    noStyle
                                    rules={[
                                        {required: true, message: 'กรุณากรอกหมายเลขโทรศัพท์'},
                                        {pattern: /^0\d{9}$/, message: 'กรุณากรอกให้ครบ 10 หลักและขึ้นต้นด้วย 0'}
                                    ]}
                                >
                                    <Input maxLength={10} placeholder="000 000 0000"/>
                                </Form.Item>
                                <Button
                                    className='ant-btn-primary'
                                    onClick={handleOTPRequest}
                                    disabled={
                                        !phoneNumberValue ||
                                        phoneNumberValue.length !== 10 ||
                                        phoneNumberValue[0] !== '0' ||
                                        showTimer
                                    }
                                >
                                    ขอรหัส OTP
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="ระบุ OTP ที่ได้รับทาง SMS"
                            name="otp"
                            rules={[
                                {required: true, message: 'กรุณากรอกรหัส OTP'},
                                {pattern: /^\d{6}$/, message: 'กรุณากรอกให้ครบ 6 หลัก'}
                            ]}
                        >
                            <Input maxLength={6} placeholder="รหัส OTP"/>
                        </Form.Item>
                        {showTimer && (
                            <div className="contentCenter">
                                <Typography.Text className="mr-[0.5em]">
                                    รหัสจะหมดอายุ ภายในอีก
                                </Typography.Text>
                                <Statistic.Countdown
                                    value={deadLineTime}
                                    format="m:ss"
                                    valueStyle={{color: '#1890FF', fontSize: '14px'}}
                                    onFinish={() => {
                                        setDeadLineTime(0)
                                        setRequestOtpAgain(true)
                                    }}
                                />
                                <Typography.Text className="ml-[0.5em]">
                                    นาที
                                </Typography.Text>
                            </div>
                        )}
                        <Form.Item className="mb-0">
                            <Button
                                htmlType="submit"
                                size="large"
                                block
                                className="ant-btn-primary my-[1em] mx-0"
                                disabled={!showTimer || !otpValue || otpValue.length !== 6}
                            >
                                ยืนยัน
                            </Button>
                        </Form.Item>
                        {requestOtpAgain && (
                            <div className="text-center">
                                <Button
                                    type="link"
                                    size="large"
                                    onClick={handleOTPRequest}
                                >
                                    ขอรหัส OTP อีกครั้ง
                                </Button>
                            </div>
                        )}
                    </Form>
                </div>
            </Card>
        </ResultPage>
    )
}

export default OTPVerify
