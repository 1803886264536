import React, {useEffect, useState} from 'react'
import {ConfigProvider, Segmented, Typography} from 'antd'
import TabSendProof from './tabs/TabSendProof'
import TabWaiting from './tabs/TabWaiting'
import TabApprove from './tabs/TabApprove'
import {useLocation, useNavigate} from 'react-router-dom'
import {NavBar} from 'antd-mobile'

const SendProof = () => {
    const location = useLocation()
    const [tab, setTab] = useState<string | number>(
        location.state ? location.state.tab : 'แจ้งหลักฐาน'
    )
    const navigate = useNavigate()

    useEffect(() => {
        if (location.state && location.state.tab) {
            setTab(location.state.tab)
        }
    }, [location.state])

    const tabChange = (value: string | number) => {
        setTab(value)
        navigate('/sendProof', {state: {tab: value}, replace: true})
    }

    return (
        <div className="backgroundPage">
            <NavBar
                style={{color: 'white', '--height': '60px'}}
                backArrow
                onBack={() => navigate('/')}
            >
                <Typography.Title level={4} className="m-0 text-white font-bold">
                    แจ้งหลักฐานการชำระเงิน
                </Typography.Title>
            </NavBar>
            <ConfigProvider
                theme={{
                    components: {
                        Segmented: {
                            itemHoverColor: '#FD414C',
                            colorBgLayout: '#FFD6D6',
                            itemColor: '#FD414C',
                            itemSelectedColor: '#FD414C',
                        }
                    }
                }}
            >
                <div className="pt-0 px-[1em] pb-[1.25em]">
                    <Segmented
                        block
                        size="large"
                        options={['แจ้งหลักฐาน', 'รอดำเนินการ', 'ผลลัพธ์']}
                        value={tab}
                        onChange={tabChange}
                    />
                </div>
            </ConfigProvider>
            <div className="backgroundContent">
                {tab === 'แจ้งหลักฐาน' && <TabSendProof/>}
                {tab === 'รอดำเนินการ' && <TabWaiting/>}
                {tab === 'ผลลัพธ์' && <TabApprove/>}
            </div>
        </div>
    )
}

export default SendProof
