import axios from 'axios'
import {notification} from 'antd'

const handleErrorResponse = (error: {
    response: { status: number; data: { error: any; message: any } }
}) => {
    if (error.response && error.response.status !== 404 && error.response.status !== 401) {
        notification['error']({
            message: error.response.data.error || error.response.data.message || error.response.data,
            description: error.response.data.message,
            duration: 5
        })
    }
    if (process.env.REACT_APP_ENV === 'development') console.error('**interceptors error**', error.response)
}

const AxiosConsigneX = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 35000,
})

AxiosConsigneX.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('debtorToken')
        if (token) {
            config.headers['Authorization'] = token
        } else {
            config.headers['Authorization'] = 'No-Token'
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

AxiosConsigneX.interceptors.response.use(
    (response) => response,
    async (error) => {
        await handleErrorResponse(error)
        return Promise.reject(error)
    }
)

export default AxiosConsigneX
