import React, {useEffect, useState} from 'react'
import {List, Tag} from 'antd-mobile'
import {Empty, Select, Skeleton, Spin} from 'antd'
import {CheckShieldOutline, CloseShieldOutline} from 'antd-mobile-icons'
import ModalInfoView from '../modals/ModalInfoView'
import AxiosConsigneX from '../../../config/AxiosConfig'
import ModalVisibleReject from '../modals/ModalVisibleReject'

const TabApprove = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [selectedData, setSelectedData] = useState<string | undefined>('')
    const [paymentDetailLoading, setPaymentDetailLoading] = useState<boolean>(false)
    const [paymentDetailData, setPaymentDetailData] = useState<any>([])
    const transactionType = localStorage.getItem('transactionType')
    const contractId = localStorage.getItem('contractId')
    const [modalVisibleReject, setModalVisibleReject] = useState<boolean>(false)

    useEffect(() => {
        if (contractId) {
            fetchPaymentDetailApprove(contractId)
        }
    }, [contractId])

    const fetchPaymentDetailApprove = async (contractId: string) => {
        setPaymentDetailLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/paymentDetailDebtorStatus', {
                params: {
                    contractId: contractId,
                    status: ['Rejected', 'Paid'].join(',')
                }
            })
            setPaymentDetailData(data)
        } catch (e) {
            setPaymentDetailData([])
        }
        setPaymentDetailLoading(false)
    }

    const checkBeforeClick = (data: any) => {
        const findRejectPerRound = paymentDetailData.find((item: { status: string; round: any }) => {
            return item.status === 'Rejected' && item?.round === data?.round
        })
        const findPaid = paymentDetailData.find((item: { status: string; round: any }) => {
            return item.status === 'Paid' && item.round === data?.round
        })
        handleOnClick(findPaid?.id, findRejectPerRound?.id, data)
    }

    const handleOnClick = (findPaid: any, findRejectPerRound: any, data: any) => {
        setSelectedData(data)
        if (findRejectPerRound === data?.id && findPaid === undefined) {
            setModalVisibleReject(true)
            setModalVisible(false)
        } else {
            setModalVisibleReject(false)
            setModalVisible(true)
        }
    }

    return (
        <div>
            <Spin spinning={paymentDetailLoading} size="large">
                <ModalVisibleReject
                    visible={modalVisibleReject}
                    setVisible={setModalVisibleReject}
                    dataPayment={selectedData}
                />
                <ModalInfoView visible={modalVisible} setVisible={setModalVisible} dataPayment={selectedData}/>
                <Select
                    value={contractId}
                    options={contractId && contractId !== 'ไม่มีสัญญา' ? [{
                        label: 'สัญญา' + transactionType + ' : ' + contractId,
                        value: contractId
                    }] : []}
                    disabled
                    className="w-[100%] my-[1em] mx-[0px]"
                />
                <Skeleton loading={paymentDetailLoading} active>
                    {paymentDetailData && paymentDetailData.length > 0 ? (
                        <List style={{'--border-top': 'none'}}>
                            {paymentDetailData.map((item: any) => (
                                <List.Item
                                    key={item.round}
                                    prefix={item.status === 'Paid' ?
                                        <CheckShieldOutline className="text-[24px text-[#14B724]"/> :
                                        <CloseShieldOutline className="text-[24px text-[#FF0000]"/>}
                                    description={
                                        <div className="text-[12px]">
                                            ชำระดอกเบี้ยงวดที่ {item.round}/{item.allRound}
                                        </div>
                                    }
                                    extra={
                                        <>
                                            {item.status === 'Paid' && (
                                                <Tag color="success" fill="outline"
                                                    style={{'--background-color': '#D9F7BE'}}>
                                                    อนุมัติแล้ว
                                                </Tag>
                                            )}
                                            {item.status === 'Rejected' && (
                                                <Tag color="danger" fill="outline"
                                                    style={{'--background-color': '#FFC0CB'}}>
                                                    ถูกปฏิเสธ
                                                </Tag>
                                            )}
                                        </>
                                    }
                                    onClick={() => checkBeforeClick(item)}
                                    arrow={false}
                                >
                                    <div className="text-[15px]">{item.month}</div>
                                </List.Item>
                            ))}
                        </List>
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            className="mt-[2em]"
                        />
                    )}
                </Skeleton>
            </Spin>
        </div>
    )
}

export default TabApprove
