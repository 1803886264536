import React from 'react'
import {Button, Flex, Image, Typography} from 'antd'
import loginLogo from '../svg/login/LoginLock.svg'
import liff from '@line/liff'
import {useNavigate} from 'react-router-dom'

const Login = () => {
    const navigate = useNavigate()

    const handleLoginLiff = async () => {
        if (process.env.REACT_APP_ENV === 'production') {
            liff.login()
            if (liff.isLoggedIn()) {
                navigate('/otpVerify')
            }
        } else {
            navigate('/otpVerify')
        }
    }

    return (
        <Flex align="center" justify="center" vertical className="h-[100vh] py-0 px-[24px]">
            <Image preview={false} src={loginLogo} width={120} height={120}/>
            <Typography.Title level={4}>กรุณาเข้าสู่ระบบ ConsigneX</Typography.Title>
            <Typography.Text className="text-[20px]">เพื่อเริ่มติดตามสัญญาของท่าน</Typography.Text>
            <Button
                className="bg-[#FD414C] text-white mt-[1em] font-bold"
                size="large"
                block
                onClick={handleLoginLiff}
            >
                เข้าสู่ระบบ
            </Button>
        </Flex>
    )
}

export default Login