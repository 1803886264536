import React, {useEffect, useState} from 'react'
import {Button, Divider, ProgressCircle, Tag} from 'antd-mobile'
import {Badge, Card, Col, Row, Spin} from 'antd'
import {useNavigate, useParams} from 'react-router-dom'
import AxiosConsigneX from '../../../config/AxiosConfig'
import {formatDate, formatNumber} from '../../../util/helper'

const TabInterestPayment: React.FC = () => {
    const navigate = useNavigate()
    const {propertyId} = useParams()
    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchInterest()
    }, [])

    const fetchInterest = async () => {
        setLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/detail', {
                params: {
                    contractId: decodeURIComponent(propertyId!)
                }
            })
            setData(data)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    return (
        <>
            <Spin spinning={loading} size="large" className="mt-[2em]">
                <Card>
                    <Row>
                        <Col span={12} className="text-[16px] font-bold">
                            เลขที่สัญญา
                        </Col>
                        <Col span={12} className="text-right">
                            <Tag
                                round
                                color="#FFDFE1"
                                style={{'--text-color': 'var(--adm-color-text)'}}
                                className="text-[12px] font-bold py-[4px] px-[8px]"
                            >
                                {data.contractId || 'ไม่มีข้อมูล'}
                            </Tag>
                        </Col>
                    </Row>
                    <Row className="mt-[1em]">
                        <Col span={12} className="text-center">
                            <ProgressCircle
                                percent={parseFloat(formatNumber((data?.transferAmount * 100) / data?.amountInterest || 0))}
                                style={{
                                    '--size': '130px',
                                    '--track-width': '10px',
                                    '--fill-color': '#52C41A',
                                }}
                            >
                                <div className="font-bold text-[15px]">
                                    {parseFloat(formatNumber((data?.transferAmount * 100) / data?.amountInterest || 0))} %
                                </div>
                                <div style={{fontSize: '10px', color: 'var(--adm-color-weak)'}}>Complete</div>
                            </ProgressCircle>
                        </Col>
                        <Col span={10} offset={2}>
                            <Badge
                                color="#52C41A"
                                text={
                                    <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                        ดอกเบี้ยที่ชำระแล้ว
                                    </span>
                                }
                            />
                            <div className="font-bold ml-[13px]">
                                THB {data.transferAmount ? Math.trunc((data.transferAmount * 100) / 100).toLocaleString() : 0}
                            </div>
                            <Badge
                                color="red"
                                text={
                                    <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                        ดอกเบี้ยคงค้าง
                                    </span>
                                }
                            />
                            <div className="font-bold ml-[13px]">
                                THB {data?.accruedInterest ? Math.trunc((data?.accruedInterest * 100) / 100).toLocaleString() : 0}
                            </div>
                            <Badge
                                color="#DCDCDC"
                                text={
                                    <span style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                        ดอกเบี้ยคงเหลือ
                                    </span>
                                }
                            />
                            <div className="font-bold ml-[13px]">
                                THB {data.remainingInterest ? Math.trunc((data.remainingInterest * 100) / 100).toLocaleString() : 0}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-[15px]">
                        <Col span={12}>
                            <span>ยอดชำระทั้งหมด</span>
                        </Col>
                        <Col span={12} className="text-right">
                            <span className="font-bold">
                                        THB{' '}
                                <span>
                                    {data?.amountInterest
                                        ? Math.trunc((data?.amountInterest * 100) / 100).toLocaleString()
                                        : 0}
                                </span>
                            </span>
                        </Col>
                    </Row>
                    <Divider className="my-[0.5em] mx-0"/>
                    <Row>
                        <Col span={12}>
                            <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>จำนวนงวดที่ชำระแล้ว</div>
                        </Col>
                        <Col span={12} className="text-right">
                            <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>วันที่เริ่มสัญญา</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} className="font-bold text-[14px]">
                            {data.round || 0} จาก {data.duration || 0}
                        </Col>
                        <Col span={12} className="text-right font-bold text-[14px]">
                            {formatDate(data.contractDate ? data.contractDate : 'ไม่มีข้อมูล')}
                        </Col>
                    </Row>
                </Card>
                <Row className="mt-[1em]">
                    <Col span={12} className="text-[16px] font-semibold">
                        <div className="text-[16px] font-semibold">การชำระที่กำลังมาถึง</div>
                    </Col>
                    <Col span={12} className="text-right">
                        <Tag
                            color="#DADADA"
                            round
                            style={{'--text-color': 'var(--adm-color-text)',}}
                            className="text-[12px] font-bold py-[4px] px-[8px]"
                        >
                            {data.nextRound}
                        </Tag>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="text-[16px] font-semibold">
                            ฿{data.amountNextRound ? data.amountNextRound.toLocaleString() : '0'}
                        </div>
                    </Col>
                    <Col span={12} className="text-right">
                        <div style={{color: 'red'}}>ครบกำหนด {formatDate(data.dueDate || 'ไม่มีกำหนด')}</div>
                    </Col>
                </Row>
                <Button
                    block
                    color="primary"
                    className="bg-[#0A52BD] mt-[2em]"
                    onClick={() => navigate('/sendProof', {
                        state: {
                            tab: 'แจ้งหลักฐาน'
                        }
                    })}
                >
                    ส่งหลักฐานการชำระเงิน
                </Button>
            </Spin>
        </>
    )
}

export default TabInterestPayment
