import React, {useState} from 'react'
import {Button, Col, Row, Upload, UploadFile} from 'antd'
import Proof from '../../svg/confirm/Proof.svg'
import {useLocation} from 'react-router-dom'
import {EyeOutlined} from '@ant-design/icons'
import ModalSendProofSuccess from './modals/ModalSendProofSuccess'
import {Image, ImageViewer} from 'antd-mobile'
import NavbarComponent from '../../components/Navbar'
import AxiosConsigneX from '../../config/AxiosConfig'

const SendProofConfirmPage = () => {
    const location = useLocation()
    const dataConfirm = location.state
    const [modalVisible, setModalVisible] = useState(false)
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [loading, setLoading] = useState(false)

    const handlePreview = async (file: UploadFile) => {
        if (file.preview) {
            setPreviewImage(file.preview)
            setPreviewOpen(true)
        } else {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreviewImage(reader.result as string)
                setPreviewOpen(true)
            }
            reader.readAsDataURL(file.originFileObj as Blob)
        }
    }

    const handleSubmit = async (values: any) => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('contractId', values.contractId)
            formData.append('round', values.round)
            formData.append('transferAmount', values.transferAmount)
            formData.append('date', values.date)
            formData.append('time', values.time)
            formData.append('accountId', values.account)
            formData.append('file', values.upload[0].originFileObj)
            formData.append('note', values.note === undefined ? null : values.note)
            if (values.reject === true) {
                await AxiosConsigneX.post('/contract/saveTransferDebtorRejected', formData)
            } else {
                await AxiosConsigneX.put('/contract/saveTransferDebtor', formData)
            }
            setModalVisible(true)
        } catch (e) {
            // handle in interceptor
        }
        setLoading(false)
    }

    return (
        <div className="backgroundPage">
            <ModalSendProofSuccess visible={modalVisible} setVisible={setModalVisible}/>
            <NavbarComponent title="ยืนยันข้อมูล" back/>
            <div className="backgroundContent">
                {dataConfirm ? (
                    <>
                        <ImageViewer
                            visible={previewOpen}
                            image={previewImage}
                            onClose={() => setPreviewOpen(false)}
                        />
                        <div className="mt-[1em] flex items-center justify-center">
                            <img
                                src={Proof}
                                alt="proof"
                                width={80}
                                height={80}
                            />
                        </div>
                        <Row className="mt-[1em]">
                            <Col span={12}>
                                <div className="my-[10px]">เลขที่สัญญา</div>
                                <div className="my-[10px]">งวดที่ชำระดอกเบี้ย</div>
                                <div className="my-[10px]">ยอดเงินที่โอน</div>
                                <div className="my-[10px]">วันที่โอนเงิน</div>
                                <div className="my-[10px]">เวลาที่โอนเงิน</div>
                            </Col>
                            <Col span={12} className="text-right font-semibold">
                                <div className="my-[10px]">{dataConfirm?.contractId}</div>
                                <div className="my-[10px]">
                                    {`${dataConfirm?.roundData?.round}/${dataConfirm?.roundData?.allRound} ${dataConfirm?.roundData?.month} ${dataConfirm?.roundData?.year}`}
                                </div>
                                {dataConfirm?.transferAmount ?
                                    <div className="my-[10px]">
                                        {dataConfirm?.transferAmount?.toLocaleString() + ' บาท'}
                                    </div>
                                    :
                                    <div className="my-[10px]">-</div>
                                }
                                <div className="my-[10px]">{dataConfirm?.date || '-'}</div>
                                <div className="my-[10px]">{dataConfirm?.time || '-'}</div>
                            </Col>
                        </Row>
                        <Row
                            className="mb-[1em] border-[1px] border-solid border-[#d9d9d9] p-[1em] rounded-[5px] justify-evenly items-center font-bold"
                        >
                            <div>
                                <div>
                                    {dataConfirm?.accountData?.bank === dataConfirm?.accountData?.type ?
                                        'บัญชี' + dataConfirm?.accountData?.bank : 'ธนาคาร' + dataConfirm?.accountData?.bank
                                    }
                                </div>
                                <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                    ชื่อบัญชี : {dataConfirm?.accountData?.name}
                                </div>
                                <div style={{fontSize: '12px', color: 'var(--adm-color-weak)'}}>
                                    เลขที่บัญชี : {dataConfirm?.accountData?.accountNumber}
                                </div>
                            </div>
                            <Image
                                src={dataConfirm?.bankImage?.find((bank: {
                                    bankName: any
                                }) => bank.bankName === dataConfirm?.accountData?.bank)?.logo}
                                height={50}
                                width={50}
                                className="ml-[2em] rounded-[10%]"
                            />
                        </Row>
                        <Row className="mb-[1em]">
                            <Col span={24}>
                                {dataConfirm?.upload && dataConfirm?.upload?.length > 0 ? (
                                    <Upload
                                        listType="picture"
                                        fileList={dataConfirm?.upload}
                                        showUploadList={{
                                            showRemoveIcon: true,
                                            showDownloadIcon: false,
                                            removeIcon: <EyeOutlined className="text-[20px]"/>
                                        }}
                                        onRemove={handlePreview}
                                    />
                                ) : (
                                    'No files uploaded'
                                )}
                            </Col>
                        </Row>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            className="bg-[#0A52BD}"
                            onClick={() => handleSubmit(dataConfirm)}
                            loading={loading}
                        >
                            ยืนยัน
                        </Button>
                    </>
                ) : (
                    <h2 className="text-center">ไม่พบข้อมูล</h2>
                )}
            </div>
        </div>
    )
}

export default SendProofConfirmPage
