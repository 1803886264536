import React, {useEffect, useState} from 'react'
import {List} from 'antd-mobile'
import UserIcon from '../../svg/document/UserDoc.svg'
import CollateralIcon from '../../svg/document/CollateralDoc.svg'
import FinanceIcon from '../../svg/document/FinancialDoc.svg'
import {useLocation, useNavigate} from 'react-router-dom'
import AxiosConsigneX from '../../config/AxiosConfig'
import {Empty, Select, Skeleton, Spin} from 'antd'

const ListFolder = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [contractData, setContractData] = useState<any>([])
    const [contractLoading, setContractLoading] = useState<boolean>(false)
    const [selectedContract, setSelectedContract] = useState<any>(null)

    useEffect(() => {
        fetchContract()
    }, [])

    useEffect(() => {
        if (contractData && contractData.length > 0) {
            setSelectedContract(location.state?.contractId ? location.state.contractId : contractData[0].id)
        } else {
            setSelectedContract('ไม่มีสัญญา')
        }
    }, [contractData])

    const fetchContract = async () => {
        setContractLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract')
            setContractData(data)
        } catch (e) {
            // handle in interceptor
        }
        setContractLoading(false)
    }

    const contract = contractData.map((item: any) => ({
        label: 'สัญญา' + item.transactionType + ' : ' + item.id,
        value: item.id,
    }))

    const folder = [
        {
            icon: <img src={UserIcon} alt="icon" width={30} height={30}/>,
            name: 'เอกสารส่วนบุคคล',
            type: 'Personal'
        },
        {
            icon: <img src={CollateralIcon} alt="icon" width={30} height={30}/>,
            name: 'เอกสารหลักประกัน',
            type: 'Assurance'
        },
        {
            icon: <img src={FinanceIcon} alt="icon" width={30} height={30}/>,
            name: 'เอกสารทางการเงิน',
            type: 'Financial'
        }
    ]

    const handleFolderClick = (item: any) => {
        navigate(`/document/${item.type}/${encodeURIComponent(selectedContract)}`)
    }

    return (
        <div>
            <Spin spinning={contractLoading} size="large">
                <Select
                    options={contract}
                    loading={contractLoading}
                    disabled={contractLoading || contractData.length === 0}
                    value={selectedContract}
                    onChange={setSelectedContract}
                    className="w-[100%] my-[1em]"
                />
                <Skeleton loading={contractLoading} active>
                    {contractData && contractData.length > 0 ? (
                        <List style={{'--border-top': 'none', '--padding-left': '0', '--padding-right': '0'}}>
                            {folder.map((item, index) => (
                                <List.Item
                                    key={index}
                                    prefix={
                                        <div className="flex bg-[#BFD9FF] rounded-[6px] p-[8px] my-[8px] mx-[0px]">
                                            {item.icon}
                                        </div>
                                    }
                                    onClick={() => handleFolderClick(item)}
                                >
                                    <div className="text-[13px] font-semibold">{item.name}</div>
                                </List.Item>
                            ))}
                        </List>
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            className="mt-[2em]"
                        />
                    )}
                </Skeleton>
            </Spin>
        </div>
    )
}

export default ListFolder
