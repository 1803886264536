import React, {useEffect, useState} from 'react'
import {List, Tag} from 'antd-mobile'
import {FieldTimeOutlined} from '@ant-design/icons'
import {Empty, Select, Skeleton, Spin} from 'antd'
import ModalInfoView from '../modals/ModalInfoView'
import AxiosConsigneX from '../../../config/AxiosConfig'

const TabWaiting = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [selectedData, setSelectedData] = useState<string | undefined>('')
    const [paymentDetailLoading, setPaymentDetailLoading] = useState<boolean>(false)
    const [paymentDetailData, setPaymentDetailData] = useState<any>([])
    const transactionType = localStorage.getItem('transactionType')
    const contractId = localStorage.getItem('contractId')

    useEffect(() => {
        if (contractId) {
            fetchPaymentDetailWaiting(contractId)
        }
    }, [contractId])

    const fetchPaymentDetailWaiting = async (contractId: string) => {
        setPaymentDetailLoading(true)
        try {
            const {data} = await AxiosConsigneX.get('/contract/paymentDetailDebtorStatus', {
                params: {
                    contractId: contractId,
                    status: 'Pending'
                }
            })
            setPaymentDetailData(data)
        } catch (e) {
            setPaymentDetailData([])
        }
        setPaymentDetailLoading(false)
    }

    const handleOnClick = (data: any) => {
        setSelectedData(data)
        setModalVisible(true)
    }

    return (
        <div>
            <Spin spinning={paymentDetailLoading} size="large">
                <ModalInfoView visible={modalVisible} setVisible={setModalVisible} dataPayment={selectedData}/>
                <Select
                    value={contractId}
                    options={contractId && contractId !== 'ไม่มีสัญญา' ? [{
                        label: 'สัญญา' + transactionType + ' : ' + contractId,
                        value: contractId
                    }] : []}
                    disabled
                    className='w-[100%] my-[1em] mx-[0]'
                />
                <Skeleton loading={paymentDetailLoading} active>
                    {paymentDetailData && paymentDetailData.length > 0 ? (
                        <List style={{'--border-top': 'none'}}>
                            {paymentDetailData.map((item: any) => (
                                <List.Item
                                    key={item.round}
                                    prefix={<FieldTimeOutlined className="text-[24px] text-[#154A98]"/>}
                                    description={
                                        <div className="text-[12px]">
                                            ชำระดอกเบี้ยงวดที่ {item.round}/{item.allRound}
                                        </div>
                                    }
                                    extra={
                                        <Tag
                                            color="warning"
                                            fill="outline"
                                            style={{'--background-color': '#FFE4CA'}}
                                        >
                                            รอการอนุมัติ
                                        </Tag>
                                    }
                                    onClick={() => handleOnClick(item)}
                                    arrow={false}
                                >
                                    <div className="text-[15px]">{item.month}</div>
                                </List.Item>
                            ))}
                        </List>
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            className="mt-[2em]"
                        />
                    )}
                </Skeleton>
            </Spin>
        </div>
    )
}

export default TabWaiting
