import React, {useState} from 'react'
import {ConfigProvider, Segmented} from 'antd'
import TabAssetDetail from './tabs/TabAssetDetail'
import TabContractDetail from './tabs/TabContractDetail'
import TabInterestPayment from './tabs/TabInterestPayment'
import {useLocation} from 'react-router-dom'
import NavbarComponent from '../../components/Navbar'

const Property = () => {
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState<string | number>(
        location.state ? location.state.tab : 'propertyDetail'
    )

    const options = [
        {
            label: 'หลักทรัพย์',
            value: 'assetDetail'
        },
        {
            label: 'สัญญา',
            value: 'contractDetail'
        },
        {
            label: 'ชำระดอกเบี้ย',
            value: 'interestPayment'
        }
    ]

    return (
        <div className="backgroundPage">
            <NavbarComponent title="ทรัพย์ที่ทำธุรกรรม" back/>
            <ConfigProvider
                theme={{
                    components: {
                        Segmented: {
                            itemHoverColor: '#FD414C',
                            colorBgLayout: '#FFD6D6',
                            itemColor: '#FD414C',
                            itemSelectedColor: '#FD414C',
                        }
                    }
                }}
            >
                <div className="pt-0 px-[1em] pb-[1.25em]">
                    <Segmented
                        block
                        size="large"
                        options={options}
                        value={selectedTab}
                        onChange={setSelectedTab}
                    />
                </div>
            </ConfigProvider>
            <div className="backgroundContent">
                {selectedTab === 'assetDetail' && <TabAssetDetail/>}
                {selectedTab === 'contractDetail' && <TabContractDetail/>}
                {selectedTab === 'interestPayment' && <TabInterestPayment/>}
            </div>
        </div>
    )
}

export default Property
